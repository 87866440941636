import React from 'react';
import { useHistory } from 'react-router-dom'

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import * as paths from '../../../routes/paths'

// shared
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker';

const useStyles = makeStyles((theme) => ({
    root:{
        backgroundImage: `url(/images/home/online-program-bach.png)`,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('xs')]:{
            backgroundImage: `url(/images/home/online-program-bach-xs.png)`,
        }
    },
    content:{
        padding: '65px 0 65px',
        [theme.breakpoints.down('xs')]:{
            padding: '50px 0 50px',
            '& .subcontent':{
                maxWidth: 328
            },
            '& button':{
                width: '100%'
            }
        }
    }
}));

const OnlineBachProgram = () => {
    const history = useHistory();
    const classes = useStyles();

    const goTo = () => {
        const eventData = {};
        eventData['source'] = 'landing';
        eventTracker('ver_membresías', eventData, ['mixpanel','google', 'hubspot']);
        //
        history.push(paths.MEMBRESIAS);
    };

    return (
        <ResponsiveContainer className={classes.root} id="online-bach-program-root">
            <Box className={classes.content}>
                <Box display='flex' gridGap={24} flexDirection='column' alignItems='center' marginBottom='48px'>
                    <Box display='flex' gridGap={16} flexDirection='column' alignItems='center'>
                        <Typography variant='h1'>Estudia nuestro programa de Bachillerato 100% Online</Typography>
                        <Box display='flex' alignItems='baseline' justifyContent="center">
                            <Typography variant='subtitle1' className='text-orange-300'>desde</Typography>
                            <Typography variant='h1' className='text-orange-300 h2-xs'>&nbsp;$479 pesos</Typography>
                            <Typography variant='h2' className='text-orange-300'>&nbsp;por mes</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant='subtitle1' className='subcontent'>
                            Obtén tu 
                            <span className='text-blue-300'>&nbsp;título avalado por la SEP&nbsp;</span>
                            sin costo extra con cualquiera de nuestras membresías.
                        </Typography>
                    </Box>
                </Box>
                <Button variant='contained' color='primary' onClick={goTo}>Ver membresías</Button>
            </Box>
        </ResponsiveContainer>
    );
};

export default OnlineBachProgram;