import React, { useState, useEffect } from 'react';
import { getSessionCookie, setSessionCookie } from '../../../../../shared/utils/Sessions'; 
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph, getUserPhoto  } from '../../../../../services/GraphServiceNew';
import { loginRequest } from '../../../../../config/authConfig';
import { useHistory, useLocation } from 'react-router-dom'; 

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../../../redux/actions/userActions'
import * as profilePicActions from '../../../../../redux/actions/profilePicActions';
import * as menuActions from '../../../../../redux/actions/menuActions';

import { MEMBERSHIPS } from '../../../../../shared/constants/memberships';
import { conektaWSUpgradeMembership } from '../../../../../shared/utils/conektaWSUpgradeMembership';

//Sentry
import * as Sentry from "@sentry/react";

//SDK
import { LoginService, ProgramService, UserService, CourseService,  MIXPANEL_EVENT } from '@sdk-point/talisis'; 
import { Mixpanel } from '../../../../../shared/components/Mixpanel/mixpanel';
import * as Cookies from "js-cookie";
import { log } from '../../../../../shared/utils/console.js'
import * as legacyCheckoutSession from '../../../../../shared/utils/checkoutSession-legacy.js';
import * as legacyCheckoutHelpers from '../../../../../shared/utils/checkoutHelpers-legacy.js';
import * as legacyCheckoutActions from '../../../../../redux/actions/legacyCheckoutActions.js';
import * as skuCheckoutActions from '../../../../../redux/actions/checkoutActions.js';
import * as skuCheckoutSession from '../../../../../shared/utils/checkoutSession.js';
import * as skuCheckoutHelper from '../../../../../shared/utils/checkoutHelpers.js';
import { fillUserData, setUser } from '../../../../../shared/components/EventTracker/EventTracker';

const LoginServiceSDK = new LoginService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const ProgramServiceSDK = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const CourseServiceSDK = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const AuthScreen = props => {
    // const location = useLocation();
    // const { person_id: reduxPersonId } = useSelector((state) => state.userReducer.user);
    const userLoggedIn = useIsAuthenticated();
    const history = useHistory();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { applyUser, onLoginError, handleLoader, loading, signupData, onOpenAutoSignup, getUserMenu}  = props;
    const [userState, setUserState] = useState({
        isAuthenticated: false,
        user: {},
        error: null
    });

    // Legacy Checkout Reducer
    const { order: legacyOrder, orderQuantity } = useSelector((state) => state.legacyCheckoutReducer);
    // SKU Checkout Reducer
    const { order: skuOrder } = useSelector((state) => state.checkoutReducer);
    const dispatch = useDispatch();

    const getCompany = async domain => {
        log('entró al get company con el dominio: ', domain)

        switch (domain) {
            case 'red.unid.mx': 
                return {ou: 'unid', client_id: 'unid', ouLabel: 'UNID', ouId: 'UNID'}
            case 'unid.mx': 
                return {ou: 'unid', client_id: 'unidStaff', ouLabel: 'UNID', ouId: 'UNID'}
            case 'u-erre.mx': 
                return {ou: 'uerre', client_id: 'uerre', ouLabel: 'U-ERRE', ouId: 'UERRE'}
            case 'u-erre.edu.mx': 
                return {ou: 'uerre', client_id: 'talisis', ouLabel: 'U-ERRE', ouId: 'UERRE'}
            case 'iesalud.edu.mx': 
                return {sso: 'google', ou: 'iesalud', client_id: 'uerre', ouLabel: 'IESALUD', ouId: 'IESALUD'}
            case 'talisis.com': 
                log('seteando el dominio: ', domain)
                return {ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL'}
            case 'miadvenio.com': 
                return {ou: 'advenio', client_id: 'miAdvenio', ouLabel: 'Advenio', ouId: 'ADV'}
            case 'harmonhall.edu.mx': 
                return {sso: 'google', ou: 'hh', client_id: 'uerre', ouLabel: 'Harmon Hall', ouId: 'HH'}
            case 'al.harmonhall.edu.mx': 
                return {sso: 'google', ou: 'hh', client_id: 'uerre', ouLabel: 'Harmon Hall', ouId: 'HH'}
            case 'soy.harmonhall.edu.mx': 
                return {ou: 'hh', client_id: 'soyHH', ouLabel: 'Harmon Hall', ouId: 'HH'}
            case 'familia.talisis.com': 
                return {ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL'}
            default:
                return {ou: 'talisis', client_id: 'talisis', ouLabel: 'Talisis', ouId: 'TAL'}
        }
    }

    const signIn = async () => {
        handleLoader(true)
        try {   
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then(async (response) => {
                
                const b2cUser = response.idTokenClaims;
                const email = b2cUser.email;
                var domain = email.substring(email.lastIndexOf("@") +1);
                const domainData = await getCompany(domain);
                let profile = {
                    userName: b2cUser.given_name,
                    email: email,
                    ou: domainData.ou
                }
                log(profile);
                LoginServiceSDK.studentCode(profile).then( async student => {
                    log(student)
                    if(student.error) {
                        const errData = {
                            message: <>Lo sentimos,  el usuario ingresado no está registrado en nuestro sistema.<br/>Te recomendamos contactar con tu coordinador para resolver esta situación.</>,
                            email: email
                        }
                        
                        const isFixed = await LoginServiceSDK.fixLogin({email});
                        if (isFixed.fixed) {
                            LoginServiceSDK.studentCode(profile).then( async student => {
                                log(student)
                                if(student.error) {
                                    const errData = {
                                        message: <>Lo sentimos,  el usuario ingresado no está registrado en nuestro sistema.<br/>Te recomendamos contactar con tu coordinador para resolver esta situación.</>,
                                        email: email
                                    }
                                    onLoginError(errData)
                                } else {
                                    if (student.profile_picture_url) {
                                        const profilePhoto = {
                                            src: student.profile_picture_url,
                                            blob: false
                                        };
                                        props.applyProfilePic(profilePhoto)
                                    }
                                                           
                                    const userInfo = {
                                        userName: `${student.first_name} ${student.last_name}`,
                                        first_name: student.first_name,
                                        last_name: student.last_name,
                                        email: profile.email,
                                        student_id: await student.student_id,
                                        person_id: await student.person_id,
                                        is_admin: await student.is_admin,
                                        gender: await student.gender,
                                        phone_number: await student.phone_number,
                                        ou: domainData.ou,
                                        ouLabel: domainData.ouLabel,
                                        company_id: domainData.ouId,
                                        company: domainData.ouId,
                                        SSO: true,
                                        membership_id: await student.membership_id || MEMBERSHIPS.FREEMIUM,
                                        interests: await student.interests || [],
                                        profile_picture_url: student.profile_picture_url,
                                    }
                                    
                                   
                                    /* Mixpanel.people({
                                        "$name": userInfo.userName,
                                        "$email": userInfo.email
                                      }); */
                
                                      Sentry.setUser({ email: userInfo.email,username: userInfo.userName });
                
                                    
                                    
                                    if(await student.is_active){
                                        setUserState({
                                            isAuthenticated: true,
                                            user: userInfo,
                                            error: null
                                        });
                    
                                        setSessionCookie(userInfo);
                                        log(userInfo)
                                        applyUser(userInfo);
                                        getUserMenu(userInfo);

                                        if( !loading && userLoggedIn && (legacyOrder?.id || skuOrder?.id)){
                                            /* Update legacy checkout order */
                                            if (legacyOrder?.id){
                                                handleLoader(false)
                                                const { getCheckoutCookie, setCheckoutCookie } = legacyCheckoutSession;
                                                const checkoutCookie = getCheckoutCookie();
                                                setCheckoutCookie({
                                                    ...checkoutCookie, 
                                                    anonymousSession: false,
                                                    personInfo: student,
                                                    personId: student.person_id,
                                                    activeAccount: true
                                                })
                                                const { setPersonToOrderOnLogin } = legacyCheckoutHelpers;
                                                const updatedOrder = await setPersonToOrderOnLogin({ personInfo: student, orderId: legacyOrder.id });
                                                const { setOrderPendingDiscount, setPurchaseOrder, setPersonInfo, setOrderQuantities } = legacyCheckoutActions;
                                                if (updatedOrder.success === true) {                    
                                                    if(updatedOrder.order?.pending_discount && updatedOrder.order?.pending_discount > 0){
                                                        dispatch(setOrderPendingDiscount(updatedOrder.order.pending_discount))
                                                    }                                
                                                    dispatch(setPurchaseOrder(updatedOrder.order))
                                                    dispatch(setPersonInfo(updatedOrder.personInfo))
                                                    dispatch(setOrderQuantities({
                                                        ...orderQuantity,
                                                        order_subtotal: updatedOrder.order.subtotal,
                                                        order_discount: updatedOrder.order.discount,
                                                        order_total: updatedOrder.order.total,
                                                    }));
                                                }
                                            }
                                            /* Update sku checkout order */
                                            if(skuOrder?.id){
                                                // Actualizar la persona en la compra activa
                                                const { updatePersonInCart } = skuCheckoutHelper;
                                                const response = await updatePersonInCart(skuOrder.id, userInfo.person_id);
                                                // Actualizar los datos de la orden en el estado reducer
                                                const { setOrder, setOrderProducts, setPersonInfo, setUserValidations } = skuCheckoutActions;
                                                dispatch(setOrder(response));
                                                dispatch(setOrderProducts(response?.cart_details));
                                                dispatch(setUserValidations({}));
                                                // Validar los datos del usuario antes de proceder al pago
                                                const validationResponse = await UserServiceSDK.validateBeforePaymentGateway(userInfo.person_id);
                                                if (!validationResponse.status) {
                                                    const phoneNumber = validationResponse.person_info.phone_number;
                                                    dispatch(setUserValidations({ phoneNumber }));
                                                } else {
                                                    dispatch(setPersonInfo(userInfo));
                                                    const { getCheckoutCookie, setCheckoutCookie } = skuCheckoutSession;
                                                    const checkoutCookie = getCheckoutCookie();
                                                    setCheckoutCookie({
                                                        ...checkoutCookie,
                                                        personInfo: userInfo,
                                                        anonymousSession: false,
                                                        activeAccount: true
                                                    });
                                                }
                                                handleLoader(false);
                                            }
                                        }
                                        else {
                                            await redirect(student)
                                            handleLoader(false)
                                        }                            
                                    } else {
                                        const errData = {
                                            message: 'Usuario inactivo',
                                            email: email
                                        }
                                        onLoginError(errData)
                                        handleLoader(false)
                                        signupData({
                                            email: profile.email,
                                            first_name: student.first_name,
                                            last_name: student.last_name,
                                            ou: domainData.ou,
                                            ouLabel: domainData.ouLabel,
                                            company_id: domainData.ouId,
                                            company: domainData.ouId,
                                            SSO: true,
                                            membership_id: await student.membership_id || MEMBERSHIPS.FREEMIUM,
                                            interests: await student.interests || []
                                        })
                                        
                                        
                                        /* Mixpanel.people({
                                            "$name": userInfo.userName,
                                            "$email": userInfo.email
                                          });
                 */
                                          Sentry.setUser({ email: userInfo.email,username: userInfo.userName });
                
                                      
                                        
                                    };
                                    
                                    conektaWSUpgradeMembership({ person_id: student.person_id, updateMembership: props.updateMembership });
                                    const userData = await fillUserData(userInfo);
                                    await setUser(userData, ['mixpanel'])
                                }
                                
                            });
                        } else {
                            onLoginError(errData)
                        }
                    } else {
                        if (student.profile_picture_url) {
                            const profilePhoto = {
                                src: student.profile_picture_url,
                                blob: false
                            };
                            props.applyProfilePic(profilePhoto)
                        }
                        
                        let tags = await getTagUser(student);
                        const userInfo = {
                            userName: `${student.first_name} ${student.last_name}`,
                            first_name: student.first_name,
                            last_name: student.last_name,
                            email: profile.email,
                            student_id: await student.student_id,
                            person_id: await student.person_id,
                            is_admin: await student.is_admin,
                            gender: await student.gender,
                            phone_number: await student.phone_number,
                            ou: domainData.ou,
                            ouLabel: domainData.ouLabel,
                            company_id: domainData.ouId,
                            company: domainData.ouId,
                            SSO: true,
                            membership_id: await student.membership_id || MEMBERSHIPS.FREEMIUM,
                            interests: await student.interests || [],
                            profile_picture_url: student.profile_picture_url,
                            userTags: tags
                        }
                        
                       
                       /*  Mixpanel.people({
                            "$name": userInfo.userName,
                            "$email": userInfo.email
                          }); */
    
                          Sentry.setUser({ email: userInfo.email,username: userInfo.userName });
    
                       
                        
                        if(await student.is_active){
                            setUserState({
                                isAuthenticated: true,
                                user: userInfo,
                                error: null
                            });
        
                            setSessionCookie(userInfo);
                            log(userInfo)
                            applyUser(userInfo);
                            getUserMenu(userInfo);

                            if( !loading && userLoggedIn && (legacyOrder?.id || skuOrder?.id)){
                                /* Update legacy checkout order */
                                if (legacyOrder?.id){
                                    handleLoader(false)
                                    const { getCheckoutCookie, setCheckoutCookie } = legacyCheckoutSession;
                                    const checkoutCookie = getCheckoutCookie();
                                    setCheckoutCookie({
                                        ...checkoutCookie, 
                                        anonymousSession: false,
                                        personInfo: student,
                                        personId: student.person_id,
                                        activeAccount: true
                                    })
                                    const { setPersonToOrderOnLogin } = legacyCheckoutHelpers;
                                    const updatedOrder = await setPersonToOrderOnLogin({ personInfo: student, orderId: legacyOrder.id })
                                    const { setOrderPendingDiscount, setPurchaseOrder, setPersonInfo, setOrderQuantities } = legacyCheckoutActions;
                                    if (updatedOrder.success === true) {                    
                                        if(updatedOrder.order?.pending_discount && updatedOrder.order?.pending_discount > 0){
                                            dispatch(setOrderPendingDiscount(updatedOrder.order.pending_discount))
                                        }                                
                                        dispatch(setPurchaseOrder(updatedOrder.order))
                                        dispatch(setPersonInfo(updatedOrder.personInfo))
                                        dispatch(setOrderQuantities({
                                            ...orderQuantity,
                                            order_subtotal: updatedOrder.order.subtotal,
                                            order_discount: updatedOrder.order.discount,
                                            order_total: updatedOrder.order.total,
                                        }));
                                    }
                                }
                                /* Update sku checkout order */
                                if(skuOrder?.id){
                                    // Actualizar la persona en la compra activa
                                    const { updatePersonInCart } = skuCheckoutHelper;
                                    const response = await updatePersonInCart(skuOrder.id, userInfo.person_id);
                                    // Actualizar los datos de la orden en el estado reducer
                                    const { setOrder, setOrderProducts, setPersonInfo, setUserValidations } = skuCheckoutActions;
                                    dispatch(setOrder(response));
                                    dispatch(setOrderProducts(response?.cart_details));
                                    dispatch(setUserValidations({}));
                                    // Validar los datos del usuario antes de proceder al pago
                                    const validationResponse = await UserServiceSDK.validateBeforePaymentGateway(userInfo.person_id);
                                    if (!validationResponse.status) {
                                        const phoneNumber = validationResponse.person_info.phone_number;
                                        dispatch(setUserValidations({ phoneNumber }));
                                    } else {
                                        // Actualizar los datos del usuario y checkout
                                        dispatch(setPersonInfo(userInfo));
                                        const { getCheckoutCookie, setCheckoutCookie } = skuCheckoutSession;
                                        const checkoutCookie = getCheckoutCookie();
                                        setCheckoutCookie({
                                            ...checkoutCookie,
                                            personInfo: userInfo,
                                            anonymousSession: false,
                                            activeAccount: true
                                        });
                                    }
                                    handleLoader(false);
                                }
                            }
                            else {
                                await redirect(student)
                                handleLoader(false)
                            }                            
                        } else {
                            const errData = {
                                message: 'Usuario inactivo',
                                email: email
                            }
                            onLoginError(errData)
                            handleLoader(false)
                            signupData({
                                email: profile.email,
                                first_name: student.first_name,
                                last_name: student.last_name,
                                ou: domainData.ou,
                                ouLabel: domainData.ouLabel,
                                company_id: domainData.ouId,
                                company: domainData.ouId,
                                SSO: true,
                                membership_id: await student.membership_id || MEMBERSHIPS.FREEMIUM,
                                interests: await student.interests || []
                            })
                            
                            
                            /* Mixpanel.people({
                                "$name": userInfo.userName,
                                "$email": userInfo.email
                              }); */
    
                              Sentry.setUser({ email: userInfo.email,username: userInfo.userName });
    
                           
                            
                        };
                        
                        conektaWSUpgradeMembership({ person_id: student.person_id, updateMembership: props.updateMembership });
                        const userData = await fillUserData(userInfo);
                        await setUser(userData, ['mixpanel'])
                    }
                    
                });

                // callMsGraph(response.accessToken).then(async user => {
                //     handleLoader(true)

                    
                // });
            // await graphService.getUserProfile('uerre').then( user => {
                                  
            //     }).catch( e => {
            //         log(e)
            //         onLoginError({open:true, message:'Estamos teniendo problemas, disculpa las molestias.'});
            //         handleLoader(false);
            //     });
            }).catch(e => {
                log(e)
                handleLoader(false)
                if (e.errorMessage.includes('timeout')) {
                    // window.location.reload(false)
                }
                
                // window.location.reload(false);
            });
        } catch (error) {
            log(error)
        }
    }

    const redirect = async (student) => {
        try {
            const responsePrograms = await ProgramServiceSDK.getProgramsInProgressByUser(student?.person_id, student?.student_id);
            const viewAll = false
            const classesTaught = await UserServiceSDK.getInstructorClasses(student?.person_id, viewAll);

            if(Boolean(responsePrograms.programs.length) || Boolean(classesTaught.length)) {
                history.push('/cursos')
            } else {
                const responseEducon = await CourseServiceSDK.getCoursesEduconInProgressByUser(student?.person_id);
                const responseAllys =  await CourseServiceSDK.getCoursesAllyInProgressByUser(student?.person_id);
                if (Boolean(responseEducon.courses.length) || Boolean(responseAllys.courses.length)) {
                    history.push('/cursos') 
                } else {
                    history.push('/home')
                }
            }
         } catch (error) {
            log('error', error)
            history.push('/home')
         }
    }

    const getTagUser = async (student) => {
        try {
            const tagsUser = await UserServiceSDK.getUserTagPersonId(student?.person_id)
            let tags = [];
            if(tagsUser.data.length) {
                for (let i in tagsUser.data){
                    tags.push(tagsUser.data[i].display_name)
                }
            }
            return tags;
         } catch (error) {
            log('error', error)
         }
    }

    useEffect(() => {
        log('auth')
        if(isAuthenticated){
            log('authenticated')
            signIn();
        }
    }, [isAuthenticated]);
    
    return ( <></> );
}

const mapStateToProps = ({userReducer, profilePicReducer}) => ({
    user: userReducer.user,
    profilePic: profilePicReducer.profilePic
});

const mapDispatchToProps = {...userActions, ...profilePicActions, ...menuActions};

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
