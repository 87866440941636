import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
//@Material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
//Component
import MembershipCard from "./MembershipCard/MembershipCard";
import FreemiumCard from "./FreemiumCard/FreemiumCard";
import MembershipHeader from './MembershipCard/Components/MembershipHeader/MembershipHeader'
import MembershipBenefits from '../../MembershipBenefits/MembershipBenefits';
//Shared
import Loader from '../../../../shared/components/Loader/LoaderContent';
import { log } from '../../../../shared/utils/console.js';
import { userIsLogged } from "../../../../shared/utils/Sessions.js";
import { getNameMembership, getPeriodMembership, getPersonAddons, getProductType } from "../../../../shared/utils/checkoutHelpers.js";
import { eventTracker } from "../../../../shared/components/EventTracker/EventTracker.js";
//CartHelper
import { addProductsToCart } from "../../../../shared/utils/cartHelper";
//Redux
import { applyCart, applyCartProducts } from "../../../../redux/actions/cartActions";
//Sdk
import { PRODUCT_TYPES, ProductService } from '@sdk-point/talisis';
import MembershipsCarousel from "../Components/MembershipsCarousel/MembershipsCarousel";
import * as paths from '../../../../routes/paths.js'
import ProductLayoutLanding from "../Components/ProductLayoutLanding/ProductLayoutLanding.jsx";
import HarmonHallAddOns from "../Components/HarmonHallAddOns/HarmonHallAddOn.jsx";
//Style
import useStyles from '../../../../assets/styles/jss/pages/memberships/versionSKU/membershipCardStyle';
const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const MembershipsContainerCard = ({ sku, feeSlugSelected, feeSlugBase, termMonths }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles({popular:false});
    const { user } = useSelector((state) => state.userReducer);
    const { cart } = useSelector((state) => state.cartReducer);
    const [loading, setLoading] = React.useState(false);
    const [membershipShow, setMembershipShow] = React.useState([]);
    const [membershipSelectedCarousel, setMembershipSelectedCarousel] = React.useState('lite');
    const [hasHarmonHallAddon, setHasHarmonHallAddon] = React.useState(null); // Validar si usuario ya cuenta con membresía Harmon Hall
    const [additionalProduct, setAdditionalProduct] = React.useState(null);
    const [productAddOn, setProductAddOn] = React.useState(null);
    const [processing, setProcessing] = React.useState(false);
    const [paddingLeftFinalTextPrice, setPaddingLeftFinalTextPrice] = React.useState('0px');
    const isLogged = userIsLogged();

    React.useEffect(() => {
        getActiveMembership();
    }, []);

    const getActiveMembership = async () => {
        try {
            setLoading(true);
            let programId = null;
            let courseId = null;
            let productInfo = null;
            // Validar si hay que consultar el producto adicional por parámetro
            if (sku) {
                const responseProduct = await productService.getProductsBySkuPattern(sku);
                // Filtrar los productos que coinciden exactamente con el sku proporcionado
                const exactMatchProducts = responseProduct?.filter(product => product.sku === sku);
                // Si hay exactamente un producto que coincide con el sku proporcionado, procesarlo
                if (exactMatchProducts.length === 1) {
                    const product = exactMatchProducts[0];
                    // Validar el tipo de producto adicional para consultar su información
                    if (product?.product_type_id === PRODUCT_TYPES.PROGRAMA) {
                        programId = product.reference_id;
                        const responseProgram = await productService.getProgramById(programId);
                        if (responseProgram != null) {
                            productInfo = responseProgram;
                        }
                    }
                    else if (product?.product_type_id === PRODUCT_TYPES.CURSO) {
                        courseId = product.reference_id;
                        const responseCourse = await productService.getCourseById(courseId);
                        if (responseCourse != null) {
                            productInfo = responseCourse;
                        }
                    }
                }
            }
            await checkPersonProducts();
            const responseActiveMemberships = await productService.getActiveMemberships(user?.person_id, programId, courseId);
            setMembershipShow([...responseActiveMemberships]);
            setAdditionalProduct(productInfo);
            let countCardShow =  responseActiveMemberships.filter(membership => membership?.active === true).length;
            let padding='0px'
            if (countCardShow === 4) {
                padding = '320px';
              } else if (countCardShow === 3) {
                padding = productInfo ? '320px' : '170px';
              } else if (countCardShow === 1) {
                padding = productInfo ? '630px' : '480px';
              } else if (countCardShow === 2) {
                padding = productInfo ? '470px' : '324px';
              }
              setPaddingLeftFinalTextPrice(padding)
        } catch (error) {
            log('Error al cargar las membresias activas', error)
        } finally {
            setLoading(false);
        }
    }

    const checkPersonProducts = async () => {
        // Validar si el usuario ya cuenta con una membresía Harmon Hall
        if (isLogged) {
            const personProducts = await getPersonAddons(user?.person_id);
            setHasHarmonHallAddon(personProducts.length > 0);
        } else {
            setHasHarmonHallAddon(false);
        }
    };

    const handleClick = async (e, membership, feeSelected) => {
        if (membership?.free) {
            handleSignup(e)
        } else {
            try {
                setProcessing(true);
                // Agregar los productos al carrito
                await continueToCart(feeSelected);
                // Redireccionar al carrito para ver el detalle.
                history.push(paths.CART);
                // Restablecer processing
                setProcessing(false);
            } catch (e) {
                log("error al agregar productos al carrito", e);
            }
        }
    }
    const handleSignup = (e) => {
        e.preventDefault();
        history.push({ pathname: paths.SIGNUP });
    }

    const continueToCart = async (product1) => {
        // Agregar membresía
        const products = [{
            "product_id": product1?.product_id,
            "product_name": product1?.product_name,
            "product_image_url": product1?.product_image_url,
            "product_description": product1?.product_description,
            "product_type_id": product1?.product_type_id,
            "quantity": 1,
            "product_price_id": product1?.product_price_id,
            "unit_price": product1?.list_price,
            "discount": 0
        }];
        // Event tracker membresía 
        if (!additionalProduct && !productAddOn) {
            handleMembershipPurchaseEventTrack(product1);
        }
        // Si hay un producto adicional como un programa o curso, agregarlo al carrito
        if (additionalProduct) {
            products.push({
                "product_id": additionalProduct?.product_id,
                "product_name": additionalProduct?.product_name,
                "product_image_url": additionalProduct?.product_image_url,
                "product_description": additionalProduct?.product_description,
                "product_type_id": additionalProduct?.product_type_id,
                "quantity": 1,
                "product_price_id": additionalProduct?.product_price_id,
                "unit_price": additionalProduct?.list_price,
                "discount": 0
            });
            // Event tracker membresía + curso
            handleMembershipCourseEventTrack(product1, additionalProduct);
        }
        // Producto adicional tipo add-on
        if (productAddOn) {
            products.push({
                "product_id": productAddOn?.product_id,
                "product_name": productAddOn?.product_name,
                "product_image_url": productAddOn?.product_image_url,
                "product_description": productAddOn?.product_description,
                "product_type_id": productAddOn?.product_type_id,
                "quantity": 1,
                "product_price_id": productAddOn?.product_price_id,
                "unit_price": productAddOn?.unit_price,
                "discount": 0
            });
            // Event tracker membresía + addon
            handleMembershipAddonEventTrack(product1, productAddOn);
        }
        let response = await addProductsToCart({
            cart_id: cart.id,
            products: [...products]
        });
        // Actualizar estado reducer del carrito
        dispatch(applyCart(response))
        dispatch(applyCartProducts(response.cart_details))
    };

    const handleMembershipPurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.product_name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "membresías";
            eventData['subscriptionType'] = getNameMembership(product?.product_name);
            eventData['periodoPago'] = getPeriodMembership(product?.product_sku);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia error", e);
        }
    };

    const handleMembershipCourseEventTrack = (product1, product2) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product2?.product_name;
            eventData['precioArticulo'] = product1?.list_price;
            eventData['articuloType'] = getProductType(product2?.product_type_id);
            eventData['subscriptionType'] = getNameMembership(product1?.product_name);
            eventData['periodoPago'] = getPeriodMembership(product1?.product_sku);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia_curso', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_curso_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia_curso error", e);
        }
    };

    const handleMembershipAddonEventTrack = (product1, productAddon) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = productAddon?.product_name;
            eventData['precioArticulo'] = productAddon?.unit_price + product1?.list_price;
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(product1?.product_name);
            eventData['source'] = 'planes';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_addon_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    return (
        <>
            <Loader loading={loading || processing} minHeight="500px">
                <Grid container justifyContent="center" className="mt-2">
                    {
                        additionalProduct !== null &&
                        <ProductLayoutLanding product={additionalProduct} />
                    }
                    {
                        membershipShow.filter(membership => membership?.active === true).map((membership, index) => {
                            if (membership?.free) {
                                return (
                                    <FreemiumCard key={index} membership={membership} handleSignup={handleSignup} />
                                )
                            }
                            return (
                                <MembershipCard key={index} membership={membership} feeSlugSelected={feeSlugSelected} feeSlugBase={feeSlugBase} additionalProduct={additionalProduct} productAddOn={productAddOn} handleClick={handleClick} />
                            )
                        })
                    }
                </Grid>
                {  !loading && 
                    <Hidden mdDown implementation='css'>
                        <Grid container style={{paddingLeft: paddingLeftFinalTextPrice}}>
                            <Typography className={classes.textSavingsByFees}>*Precio final por pago anticipado de {termMonths} meses </Typography>
                        </Grid>
                    </Hidden>
                }
                <Hidden smUp implementation='css'>
                    <Grid container justifyContent="center" className="mt-0">
                        <Typography className={classes.textSavingsByFees}>*Precio final por pago anticipado de {termMonths} meses </Typography>
                    </Grid>
                </Hidden>
            </Loader>
            {
                hasHarmonHallAddon === false && // No cargar las membresías Harmon Hall si el usuario ya cuenta con una de ellas
                <Loader loading={processing}>
                    <HarmonHallAddOns termMonths={termMonths} setProductAddOn={setProductAddOn} />
                </Loader>
            }
            <Box mt={"36px"} mb={'30px'} id="id_compare_features">
                <Typography variant="h2" align='center' className="title">Compara características</Typography>
            </Box>
            <Hidden xsDown implementation='css'>
                <Grid container justifyContent={"flex-end"} className="mt-2 pr-3 pl-3">
                    {
                        membershipShow.map((membership, index) => {
                            return (
                                <MembershipHeader key={index} membership={membership} feeSlugSelected={feeSlugSelected} feeSlugBase={feeSlugBase} handleClick={handleClick} />
                            )
                        })
                    }
                </Grid>
            </Hidden>
            <Hidden smUp implementation='css'>
                <Grid container justifyContent="center" className="mt-2">
                    <MembershipsCarousel membershipShow={membershipShow} feeSlugSelected={feeSlugSelected} feeSlugBase={feeSlugBase} setMembershipSelected={setMembershipSelectedCarousel} handleClick={handleClick} />
                </Grid>
            </Hidden>
            <Box mt={'32px'} mb={'64px'} className="pr-3">
                <MembershipBenefits currentMembership={{ slug: membershipSelectedCarousel }} />
            </Box>
        </>
    )
}


export default MembershipsContainerCard;