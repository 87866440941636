import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// core
import CheckoutPhoneError from './CheckoutPhoneError/CheckoutPhoneError';

// redux
import { setUserValidations } from '../../../redux/actions/checkoutActions';

// shared
import { log } from '../../../shared/utils/console.js';

// sdk
import { CartService } from "@sdk-point/talisis";
const cartService = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY, '');

const CheckoutUserValidations = ({ processOrderData, onCheckoutLoading }) => {
    const dispatch = useDispatch();
    const { order, userValidations } = useSelector(state => state.checkoutReducer);

    const handleSubmitPhone = async (phone) => {
        try {
            onCheckoutLoading(true);
            // Procesar la orden y actualizar estados/reducers
            const personId = order.person_id;
            const personInfo = { phone_number: phone };
            const checkoutProcess = await cartService.shoppingCartProcess(order.id, personId, personInfo);
            // Restablecer la validación del telefono cuando ya fue actualizado
            dispatch(setUserValidations({ ...userValidations, phoneNumber: true }));
            // Procesar la orden y actualizar estados/reducers
            await processOrderData(checkoutProcess, personId);
            // Actualizar loading principal
            onCheckoutLoading(false);
        } catch (error) {
            log('CheckoutUserValidations error->', error)
        }
    };

    if (!userValidations?.phoneNumber) {
        return <CheckoutPhoneError onSubmitPhone={handleSubmitPhone} />
    }

    return null;
};

export default CheckoutUserValidations;