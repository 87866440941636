//Catalogo de eventos de hubspot para prod
const EVENTS_CAT = {
    s_landing: 'pe23623758_s_landing',
    s_academias: 'pe23623758_s_academias',
    s_pago_pendiente: 'pe23623758_s_pago_pendiente',
    pago_exitoso: 'pe23623758_pago_exitoso',
    pago_rechazado: 'pe23623758_pago_rechazado',
    cta_registro: 'pe23623758_cta_registro_v2',
    comprar_membresia: 'pe23623758_comprar_membresia',
    s_planes: 'pe23623758_s_planes',
    s_metodo_pago: 'pe23623758_s_metodo_pago',
    cancelar_pago: 'pe23623758_cancelar_pago',
    finalizar_compra :'pe23623758_finalizar_compra', 
    agregar_curso: 'pe23623758_agregar_curso',
    agregar_membresia: 'pe23623758_agregar_membresia',
    agregar_membresia_curso: 'pe23623758_agregar_membresia_curso',
    agregar_membresia_addon: 'pe23623758_agregar_membresia_addon',
    eliminar_membresia_carrito: 'pe23623758_eliminar_membresia_carrito',
    eliminar_programa: 'pe23623758_eliminar_programa',
    imprimir_ficha: 'pe23623758_imprimir_ficha',
    s_pago_exitoso: 'pe23623758_s_pago_exitoso',
    eliminar_producto: 'pe23623758_eliminar_producto',
    ver_membresías: 'pe23623758_ver_membresias'

}

//Catalogo para hubspot para ambientes de prueba
const EVENTS_CAT_DEV = {
    s_landing: 'pe24397851_s_landing_v2',
    s_academias: 'pe24397851_s_academias_v2',
    s_pago_pendiente: 'pe24397851_s_pago_pendiente',
    pago_exitoso: 'pe24397851_pago_exitoso',
    pago_rechazado: 'pe24397851_pago_rechazado',
    cta_registro: 'pe24397851_cta_registro',
    comprar_membresia: 'pe24397851_comprar_membresia',
    s_planes: 'pe24397851_s_planes',
    s_metodo_pago: 'pe24397851_s_metodo_pago',
    cancelar_pago: 'pe24397851_cancelar_pago',
    finalizar_compra :'pe24397851_finalizar_compra',
    agregar_curso: 'pe24397851_agregar_curso',
    agregar_membresia: 'pe24397851_agregar_membresia',
    agregar_membresia_curso: 'pe24397851_agregar_membresia_curso',
    agregar_membresia_addon: 'pe24397851_agregar_membresia_addon',
    eliminar_membresia_carrito: 'pe24397851_eliminar_membresia_carrito',
    eliminar_programa: 'pe24397851_eliminar_programa',
    imprimir_ficha: 'pe24397851_imprimir_ficha',
    s_pago_exitoso: 'pe24397851_s_pago_exitoso',
    eliminar_producto: 'pe24397851_eliminar_producto',
    ver_membresías: 'pe24397851_ver_membresias'
}

export const getEventsCat = (eventName) => {
    if(process.env.REACT_APP_ENV === 'v3'){
        return EVENTS_CAT[eventName]
    }else{
        return EVENTS_CAT_DEV[eventName]
    }
}