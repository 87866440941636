import { defaultCheckoutCookie, setCheckoutCookie, getCheckoutCookie } from "./checkoutSession.js";
import isEmail from "./isEmail";
import isName from "./isName";
import { isValidPhoneNumber } from "./isValidPhoneNumber";
import * as paths from '../../routes/paths';
import { CHECKOUT_WORKFLOWS } from "../constants/checkout.js";
import { log } from "./console.js";

// assets
import LogoVisa from "../../assets/images/banks/visa-checkout-blanco.svg";
import LogoMaster from "../../assets/images/banks/master-card-logo.svg";
import LogoAmex from "../../assets/images/banks/american-expre-texto.svg";
import LogoSPEI from "../../assets/images/banks/spei.svg";

// sdk
import { CARD_BRANDS, PAYMENT_METHODS, CartService, UserService, PAYMENT_TYPES, PAYMENT_STATUS, ORDER_STATUS, PRODUCT_CATEGORIES, PRODUCT_TYPES, PAYMENT_LINK_STATUS, PRODUCT_SKU_PREFIX } from "@sdk-point/talisis";
import { getSourceBySourceAndCompany } from "../../design-system/utils/source.js";
import { SOURCE_NAME } from "../../design-system/constants/sources.js";
const cartServiceSDK = new CartService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
const UserServiceSDK = new UserService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

export const startCheckout = ({ history, order, user, isLogged }) => {
    const checkoutCookie = getCheckoutCookie()

    if (isLogged) {
        setCheckoutCookie({
            ...defaultCheckoutCookie,
            orderId: order?.id,
            personInfo: user,
            activeAccount: true,
            anonymousSession: false,
            isReferred: checkoutCookie.isReferred ? true : false
        });
    } else {
        setCheckoutCookie({
            ...defaultCheckoutCookie,
            orderId: order?.id,
            isReferred: checkoutCookie.isReferred ? true : false
        });
    }

    history.push(paths.CHECKOUT);
}

export const generateCheckoutWorkflow = (checkoutCookie = getCheckoutCookie()) => {
    if (checkoutCookie.anonymousSession) {
        return CHECKOUT_WORKFLOWS.ACCOUNT_FORM;
    } else {
        return CHECKOUT_WORKFLOWS.PAYMENT_METHODS;
    }
};

export const validateFormFields = ({ formFields }) => {
    const requiredFields = Object.keys(formFields);
    const _errors = {};

    const { message: errorMessage, error: hasPhoneError } = isValidPhoneNumber(formFields['phoneNumber']);

    for (const field of requiredFields) {
        if (!formFields[field]) {
            _errors[field] = 'Campo Requerido';
        }
    }

    if (!isName(formFields['firstName'])) {
        _errors['firstName'] = 'Solo se permiten letras en este campo'
    }
    if (!isName(formFields['lastName1'])) {
        _errors['lastName1'] = 'Solo se permiten letras en este campo'
    }
    if (!isName(formFields['lastName2'])) {
        _errors['lastName2'] = 'Solo se permiten letras en este campo'
    }

    if (hasPhoneError) {
        _errors['phoneNumber'] = errorMessage
    }

    if (formFields['email1'] !== formFields['email2']) {
        _errors['email2'] = 'Los correos no coinciden'
    }

    if (!isEmail(formFields['email1'])) {
        _errors['email1'] = 'El email no es valido'
    }

    const isFormComplete = Boolean(Object.keys(_errors).length === 0);

    return {
        _errors,
        isFormComplete
    }
};

export const cardBrandIcons = {
    [CARD_BRANDS.VISA]: LogoVisa,
    [CARD_BRANDS.MASTERCARD]: LogoMaster,
    [CARD_BRANDS.AMERICAN_EXPRESS]: LogoAmex
};

export const paymentMethodIcons = {
    [PAYMENT_METHODS.CARD]: 'ri-bank-card-line',
    [PAYMENT_METHODS.SPEI]: 'ri-swap-box-line',
    [PAYMENT_METHODS.CASH]: 'ri-wallet-3-line',
};

export const paymentMethodLogos = {
    [PAYMENT_METHODS.SPEI]: LogoSPEI,
};

export const updatePersonInCart = async (orderId, personId) => {
    const response = await cartServiceSDK.updatePersonInCart(orderId, personId);
    return response;
};

export const processCheckoutPayment = async (orderId = 0, paymentMethod = {}, monthly_payments = 0) => {
    try {
        const paymentGatewayMethodId = paymentMethod?.payment_gateway_method_id;
        log("paymentGatewayMethodId:", paymentGatewayMethodId);

        const paymentTokens = [];
        // Obtener los tokens de pago cuando el usuario seleccionó tarjeta de crédito/débito
        if (paymentMethod?.payment_method_id === PAYMENT_METHODS.CARD && paymentMethod?.payment_type_id === PAYMENT_TYPES.USER) {
            if (paymentMethod?.instances?.length > 0) {
                paymentMethod.instances.forEach((instance) => {
                    paymentTokens.push({
                        "token": instance.id,
                        "tenant": instance.ou
                    })
                })
            }
        }
        log("paymentTokens:", paymentTokens);

        // Procesar el pago
        const paymentGatewayEnv = process.env.REACT_APP_PAYMENT_GATEWAY_ENV || null;
        const response = await cartServiceSDK.shoppingCartPay(orderId, paymentGatewayMethodId, paymentTokens, monthly_payments, paymentGatewayEnv);
        console.log("shoppingCartPay response", response);

        return { ...response }
    } catch (e) {
        return { errorMessage: e.message };
    }
}

export const getPersonAddons = async (personId) => {
    try {
        const personAddons = await UserServiceSDK.getPersonProducts(personId, {
            category_id: PRODUCT_CATEGORIES.ADDON,
            product_type_id: PRODUCT_TYPES.LICENCIA,
            sku: PRODUCT_SKU_PREFIX.HARMON_HALL
        });
        return personAddons;
    } catch (error) {
        log('Error fetching person addons:', error);
        return [];
    }
}

export const isOrderProcessed = (orderStatusId) => {
    return [
        ORDER_STATUS.PENDING_PAYMENT,
        ORDER_STATUS.COMPLETED_PAYMENT,
        ORDER_STATUS.FAILED_PAYMENT,
        ORDER_STATUS.REDUNDED,
        ORDER_STATUS.CANCELED
    ].includes(orderStatusId);
};

export const isPaymentSuccessfulOrPending = (paymentStatusId) => {
    return [
        PAYMENT_STATUS.PAGO_EXITOSO,
        PAYMENT_STATUS.REFERENCIA_GENERADA
    ].includes(paymentStatusId);
};

export const isPaymentSuccessful = (paymentStatusId) => {
    return [
        PAYMENT_STATUS.PAGO_EXITOSO
    ].includes(paymentStatusId);
};

export const isPaymentLinkActive = (paymentLinkStatusId) => {
    return [
        PAYMENT_LINK_STATUS.PENDING
    ].includes(paymentLinkStatusId);
};

export const getMethodPayment = (payment_method_id, metadata) => {
    let paymentPayment = ''
    switch (payment_method_id) {
        case PAYMENT_METHODS.SPEI:
            paymentPayment = 'Transferencia'
            break;
        case PAYMENT_METHODS.CASH :
            paymentPayment= 'Efectivo'
            break;
        case PAYMENT_METHODS.CARD:
            if (metadata?.card_type === "debit") paymentPayment =  "TDD";
            if (metadata?.card_type === "credit") paymentPayment =  "TDC";
            break;
        default:
            paymentPayment = 'cupon'
            break;
    }
    return paymentPayment;
}

export const getPeriodMembership = (SKU) => {
    let paymentPeriod ='';
    if (SKU.includes('1M')) paymentPeriod = "mensual";
    if (SKU.includes('3M')) paymentPeriod = "trimestral";
    if (SKU.includes('6M')) paymentPeriod = "semestral";
    if (SKU.includes('12M')) paymentPeriod = "anual";
    return paymentPeriod;
}

export const getNameMembership = (name) => {
    let paymentPeriod ='';
    if (name.includes('Lite')) paymentPeriod = "Lite";
    if (name.includes('Professional')) paymentPeriod = "Professional";
    if (name.includes('Premium')) paymentPeriod = "Premium";
    return paymentPeriod;
}

export const getCourseBrand = (sourceId, companyId) => {
    let courseBrand ='';
    const sourceCompanyId = getSourceBySourceAndCompany(sourceId, companyId);
    if (sourceCompanyId) {
        courseBrand = SOURCE_NAME[sourceCompanyId].toLowerCase();
    }
    return courseBrand;
}

export const getCourseBrandFromUrl = (productImageUrl) => {
    let courseBrand ='';
    if (productImageUrl?.includes('unid')) courseBrand = 'unid';
    if (productImageUrl?.includes('uerre')) courseBrand = 'uerre';
    if (productImageUrl?.includes('coursera')) courseBrand = 'coursera';
    if (productImageUrl?.includes('crehana')) courseBrand = 'crehana';
    if (productImageUrl?.includes('linkedin')) courseBrand = 'linkedin';
    return courseBrand;
}

export const getProductType = (productTypeId) => {
    let productType = '';
    if (productTypeId === PRODUCT_TYPES.CURSO) productType = "curso";
    if (productTypeId === PRODUCT_TYPES.PROGRAMA) productType = "programa";
    if (productTypeId === PRODUCT_TYPES.MEMBRESIA) productType = "membresías";
    if (productTypeId === PRODUCT_TYPES.LICENCIA) productType = "addOn";
    return productType;
}

export const getPeriodFromSKU = (sku) => {
    const match = sku.match(/(\d+M)/);
    return match ? match[1] : null;
}

export const compareProductPeriods = (sku1, sku2) => {
    const product1Period = getPeriodFromSKU(sku1);
    const product2Period = getPeriodFromSKU(sku2);
    return product1Period === product2Period;
}

export const replaceSKUPeriod = (productSKU, productPeriod) => {
    const parts = productSKU.split('-');
    const periodRegex = /\d+M/; // Regex para identificar el componente del período
    
    parts[parts.length - 1] = parts[parts.length - 1].replace(periodRegex, productPeriod);

    return parts.join('-');
}