import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import { Button, Card, CardContent, CircularProgress, Fade, makeStyles, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Subtitles } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    cardContainer: {
        maxWidth: '620px',
        padding: '32px',
        minWidth: '520px',
        textAlign: 'center',
        position: 'relative',
    },
    closeModal: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        fontSize: '23px',
        color: theme.palette.colors.grey[600],
        cursor: 'pointer'
    },
    textErrorMessage:{
        color: '#B8B8BB'
    }
}));

const ModalClassEnrollment = ({ open, loading, error, onClose, onConfirm, onRetry=()=>{}, onSupport=()=>{}, isSecondEnrrollment = false }) => {
    const [textModal, setTextModal] = useState({
        title: '¿Estás seguro que deseas tomar esta materia?',
        subTitle: 'Una vez que inicies no podrás cambiarla.'
    });
    const classes = useStyles();

    useEffect(() => {
        if(isSecondEnrrollment){
            setTextModal({
                title: 'Segunda oportunidad',
                subTitle: 'Esta es tu segunda oportunidad para cursar y aprobar la materia. En caso de que vuelvas a reprobar, deberás contactar a tu coordinador de programa para revisar tu situación académica.'
            })
        }
    }, []);

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Fade in={open}>
                <Card width='50%'>
                    <CardContent width='40%' className={classes.cardContainer}>
                        {
                            loading ? <>
                                <Box><Typography variant="body1" align='center' style={{ marginBottom: '16px' }}>Te estamos incribiendo a la materia.<br />No cierres la ventana...</Typography></Box>
                                <Box><CircularProgress /></Box>
                            </> : <>
                                <Box className={classes.closeModal}>
                                    <i className="ri-close-fill" onClick={onClose}></i>
                                </Box>
                                {
                                    error===null || error===undefined || error===0
                                    ?
                                    <Box className='mb-2'>
                                        <i >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <path d="M20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40ZM20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM18 26H22V30H18V26ZM18 10H22V22H18V10Z" fill="#FCDC43" />
                                            </svg>
                                        </i>
                                    </Box>
                                    :
                                    null                                    
                                }
                                {
                                    error===null || error===undefined || error===0
                                    ?
                                    <>
                                        <Box><Typography variant="h4" color="primary" style={{ marginBottom: '42px' }}>{textModal.title}</Typography></Box>
                                        <Box><Typography variant="body1" style={{ marginBottom: '122px' }}>{textModal.subTitle}</Typography></Box>
                                    </>
                                    :
                                    (
                                        error===1
                                        ?
                                        <>
                                            <Box><Typography variant="h4" color="primary" className='mt-2'>¡Ups! Ocurrió un error inesperado</Typography></Box>
                                            <Box><Typography className='font-variant-v10 mt-3'>Esta materia no está disponible por el momento</Typography></Box>
                                            <Box><Typography variant='body1' className={clsx('mt-1',classes.textErrorMessage)}>Contáctanos para ayudarte a resolverlo.</Typography></Box>
                                            <Box className='pb-2'><Typography variant='body1' className={clsx('mb-4',classes.textErrorMessage)}>¡Gracias por tu comprensión y disculpa las molestias!</Typography></Box>
                                        </>
                                        :
                                        <>
                                            <Box><Typography variant="h4" color="primary" className='mt-2'>¡Ups! Algo salió mal.</Typography></Box>
                                            <Box><Typography className='font-variant-v10 mt-3'>Por favor, inténtalo de nuevo</Typography></Box>
                                            <Box><Typography variant='body1' className={clsx('mt-1',classes.textErrorMessage)}>Si el problema persiste, contáctanos para ayudarte a resolverlo.</Typography></Box>
                                            <Box className='pb-2'><Typography variant='body1' className={clsx('mb-4',classes.textErrorMessage)}>¡Gracias por tu comprensión y disculpa las molestias!</Typography></Box>
                                        </>
                                    )
                                }
                                <Box className={error===1 || error===2?'mb-3':''}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button variant='outlined' color="secondary" fullWidth onClick={error===null || error===undefined || error===0?onClose:(error===1?onClose:onSupport)}>
                                                {
                                                    error===null || error===undefined || error===0
                                                    ?
                                                    "Regresar"
                                                    :
                                                    (
                                                        error===1
                                                        ?
                                                        "Intentar más tarde"
                                                        :
                                                        "Contactar a soporte"
                                                    )
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant='contained' color="primary" fullWidth onClick={error===null || error===undefined || error===0?onConfirm:(error===1?onSupport:onRetry)}>
                                                {
                                                    error===null || error===undefined || error===0
                                                    ?
                                                    "Inscribirme ahora"
                                                    :
                                                    (
                                                        error===1
                                                        ?
                                                        "Pedir ayuda a soporte"
                                                        :
                                                        "Intentar de nuevo"
                                                    )
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ModalClassEnrollment;