import React from "react";
import clsx from 'clsx';
//@Material-ui
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//Components
import MembershipMonthlyPrice from '../../Components/MembershipMonthlyPrice';
import MembershipCrossedOutPrice from '../../Components/MembershipCrossedOutPrice';
//Styles
import useStyles from '../../../../../assets/styles/jss/pages/memberships/versionSKU/MembershipModalUpSelling/MembershipCardUpSellingSecondaryStyle';
//Utils
import {TxtBtnToSubscribe} from '../../utils/TxtBtnToSubscribe';
import { TxtSavingsByFees} from '../../utils/TxtSavingsByFees';

const MembershipCardUpSellingSecondary = ({ feeOptionShow, feeBase, onUpselling }) => {
    const classes = useStyles();
    const feesBasePrice = feeBase?.monthlyPrice * feeOptionShow?.months;
    const savingsPercentage = (feesBasePrice - feeOptionShow.list_price) / feesBasePrice *100;
    return (
        <Box>
            <Card className={clsx([classes.card,'text-left', 'pl-2'])}>
                <Grid container direction='row'>
                    <Grid item xs>
                    </Grid>
                    <Grid item xs='auto'>
                        <Typography className={classes.savingTitle}>Ahorra {Math.trunc(savingsPercentage)}%</Typography>
                    </Grid>
                </Grid>
                <Typography>
                   {feeOptionShow?.feeName}
                </Typography>
                <MembershipMonthlyPrice price={feeOptionShow?.monthlyPrice}/>
                <Grid>
                    <MembershipCrossedOutPrice priceToCrossOut={feeBase?.monthlyPrice}/>
                </Grid>
            </Card>
            <Button variant='outlined' color='secondary' className={classes.btnToSubscribe} onClick={() => { onUpselling(feeOptionShow)/*alert('Dirigiendo al carrito-> Próximamente')*/ }}>
                { TxtBtnToSubscribe(feeOptionShow?.currencySymbol,feeOptionShow?.list_price)}
            </Button>
            <Typography className={classes.textSavingsByFees}>
                {TxtSavingsByFees(feeOptionShow?.feeName, feesBasePrice-feeOptionShow?.list_price)}
            </Typography>
        </Box>
    )
} 

export default MembershipCardUpSellingSecondary;