import * as checkoutTypes from '../types/checkoutTypes';

const INITIAL_STATE = {
    order: {
        id: null,
        order_type_id: null,
        person_id: null,
        status_id: null,
        session_id: null
    },
    installments: {
        installmentOptions: [],
        selectedInstallmentOption: 1
    },
    userValidations: {
        phoneNumber: true,
    },
    orderProducts: [],
    personInfo: {},
    orderPriceDetails: {
        total: 0,
        subtotal: 0,
        bonuses: 0,
        discounts: [],
        saving: 0,
        next_payment_date: null
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case checkoutTypes.APPLY_ORDER:
            return {
                ...state,
                order: action.payload
            };

        case checkoutTypes.APPLY_ORDER_PRODUCTS:
            return {
                ...state,
                orderProducts: action.payload
            };

        case checkoutTypes.APPLY_PERSON_INFO:
            return {
                ...state,
                personInfo: action.payload
            };

        case checkoutTypes.APPLY_ORDER_PRICE_DETAILS:
            return {
                ...state,
                orderPriceDetails: action.payload
            };

        case checkoutTypes.APPLY_INSTALLMENTS_OPTIONS:
            return {
                ...state,
                installments: {
                    ...state.installments,
                    installmentOptions: action.payload,
                }
            };

        case checkoutTypes.APPLY_SELECTED_INSTALLMENT_OPTION:
            return {
                ...state,
                installments: {
                    ...state.installments,
                    selectedInstallmentOption: action.payload,
                }
            };

        case checkoutTypes.APPLY_ORDER_TOTAL:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    total: action.payload
                }
            };

        case checkoutTypes.APPLY_ORDER_SUBTOTAL:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    subtotal: action.payload
                }
            };

        case checkoutTypes.APPLY_ORDER_BONUSES:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    bonuses: action.payload
                }
            };

        case checkoutTypes.APPLY_ORDER_DISCOUNTS:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    discounts: action.payload
                }
            };

        case checkoutTypes.APPLY_ORDER_SAVINGS:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    savings: action.payload
                }
            };

        case checkoutTypes.APPLY_ORDER_NEXT_PAYMENT_DATE:
            return {
                ...state,
                orderPriceDetails: {
                    ...state.orderPriceDetails,
                    next_payment_date: action.payload
                }
            };

        case checkoutTypes.APPLY_USER_VALIDATIONS:
            return {
                ...state,
                userValidations: action.payload,
            };

        default:
            return state;
    }
}