import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

// styles
import { checkoutPhoneErrorStyles } from './checkoutPhoneErrorStyles.js';
import { isValidPhoneNumber } from '../../../../shared/utils/isValidPhoneNumber.js';

const CheckoutPhoneError = ({ onSubmitPhone }) => {
    const classes = checkoutPhoneErrorStyles();
    const history = useHistory();
    const [phone, setPhone] = React.useState("");
    const [phoneError, setPhoneError] = React.useState("");
    const handlePhoneInput = (e) => setPhone(e.target.value);
    const handleValidatePhone = () => {
        const { message, error } = isValidPhoneNumber(phone);
        if (error) {
            return setPhoneError(message);
        }
        onSubmitPhone(phone);
    };
    return (
        <Box style={{ paddingTop: '38px' }}>
            <Typography variant='h3' className={clsx(classes.phoneTitle, "text-violet-300 font-weight-700")}>Para continuar solo nos falta un dato.</Typography>
            <Typography>Compártenos tu teléfono para contactarte en caso que requieras ayuda.</Typography>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                        id="outlined-basic"
                        name="phoneNumber"
                        label="Número de celular"
                        className={classes.phoneInput}
                        variant="outlined"
                        value={phone}
                        required
                        inputProps={{ maxLength: 10 }}
                        onChange={handlePhoneInput}
                        error={Boolean(phoneError)}
                        helperText={phoneError}
                        placeholder="Número a 10 dígitos"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+52</InputAdornment>,
                            shrink: "true"
                        }}
                    />
                </Grid>
            </Grid>
            <Box className="mt-4">
                <Button variant="outlined" color="secondary" className={classes.button + " mr-2"} onClick={() => history.replace('/')}>Ir a inicio</Button>
                <Button disabled={phone.length < 1} variant="contained" color="primary" className={classes.button} onClick={handleValidatePhone}>
                    Continuar
                </Button>
            </Box>
        </Box>
    );
}

export default CheckoutPhoneError;