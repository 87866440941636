import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

// reducers 
import { applyCart, applyCartProducts, applyAddons, applySelectedAddon } from '../../redux/actions/cartActions';

// @material-ui
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

// assets
import useStyles from '../../assets/styles/jss/pages/cart/cartStyle';

// components
import CartNavigationBar from './CartNavigationBar/CartNavigationBar';
import CartDetails from './CartDetails/CartDetails.jsx';
import CartPriceDetails from './CartPriceDetails/CartPriceDetails';
import CartCoupon from './CartCoupon/CartCoupon';
import CartPayment from './CartPayment/CartPayment';
import CartTest from './components/CartTest/CartTest.jsx';
import CartProductDeleteModal from './components/CartProductDeleteModal/CartProductDeleteModal.jsx';
import HarmonHallAddons from './HarmonHallAddons/HarmonHallAddons.jsx';

// shared
import { log } from '../../shared/utils/console.js'
import { userIsLogged } from '../../shared/utils/Sessions.js';
import { getCourseBrandFromUrl, getNameMembership, getPeriodMembership, getPersonAddons, getProductType } from '../../shared/utils/checkoutHelpers.js';
import { eventTracker } from '../../shared/components/EventTracker/EventTracker.js';

// constants
import { getModalConfirmationMessage } from '../../shared/constants/carts.js'

// ds
import LoaderContent from '../../design-system/components/Loader/LoaderContent';

// cartHelper.js
import { deleteProductInCart, getCartGlobal } from '../../shared/utils/cartHelper.js';
import { getCheckoutCookie, setCheckoutCookie } from '../../shared/utils/checkoutSession.js';
import * as paths from '../../routes/paths';

//sdk
import { PRODUCT_TYPES, ProductService } from '@sdk-point/talisis';

// core utils
import { getMembershipFromCart } from './utils/getMembershipFromCart.js';
import { getProgramFromCart } from './utils/getProgramFromCart.js';
import { getAddonFromCart } from './utils/getAddonFromCart.js';
import { getThirdPartyCourseFromCart } from './utils/getThirdPartyCourseFromCart.js';

// checkout
import CheckoutMaintenanceAlert from '../Checkout/components/CheckoutMaintenanceAlert/CheckoutMaintenanceAlert.jsx';

const ProductServiceSDK = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const Cart = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [loadingAddons, setLoadingAddons] = React.useState(false);
    const { cart, cartProducts } = useSelector((state) => state.cartReducer);
    const { user } = useSelector((state) => state.userReducer);
    const [modalProps, setModalProps] = React.useState({ open: false, product: null, message: null });
    const [showAddons, setShowAddons] = React.useState(false);
    const searchParams = new URLSearchParams(location.search);
    const showDemo = searchParams.get('showDemo') === 'true';
    const personPaymenToken = searchParams.get('personPaymenToken');
    const [hasHarmonHallAddon, setHasHarmonHallAddon] = React.useState(null); // Validar si usuario ya cuenta con membresía Harmon Hall
    const [currentMembershipSKU, setCurrentMembershipSKU] = React.useState(null);
    const classes = useStyles();
    const isLogged = userIsLogged();

    const checkPersonProducts = async () => {
        // Validar si el usuario ya cuenta con una membresía Harmon Hall
        if (isLogged) {
            const personProducts = await getPersonAddons(user?.person_id);
            setHasHarmonHallAddon(personProducts.length > 0);
        } else {
            setHasHarmonHallAddon(false);
        }
    };

    const getCart = async () => {
        try {
            setLoading(true);
            await checkPersonProducts();
            const response = await getCartGlobal(personPaymenToken);
            dispatch(applyCart(response))
            dispatch(applyCartProducts(response.cart_details))
            setLoading(false);
        } catch (error) {
            log('error', error)
        }
    };

    const deleteProduct = async (product) => {
        try {
            setLoading(true);
            // Eliminar el producto en el carrito
            let response = await deleteProductInCart({
                cart_id: cart?.id,
                product_id: product?.product_id
            });
            // Event Tracker eliminar membresía
            if (product?.product_type_id === PRODUCT_TYPES.MEMBRESIA) {
                handleDeleteMembershipEventTrack(product);
            }
            // Event Tracker eliminar programa
            if (product?.product_type_id === PRODUCT_TYPES.PROGRAMA){
                handleDeleteProgramEventTrack(product);
            }
            // Event Tracker eliminar producto
            handleDeleteProductEventTrack(product);
            // Actualizar los reducers del carrito
            dispatch(applyCart(response))
            dispatch(applyCartProducts(response?.cart_details))
            setLoading(false);
            // Si el carrito no ya no tiene productos, redirigir a las membresías 
            if (!response?.cart_details?.length) {
                history.push(paths.MEMBRESIAS);
            }
        } catch (e) {
            log("error al eliminar producto", e);
        }
    };

    const handleDeleteMembershipEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.product_name;
            eventData['precioArticulo'] = product?.unit_price;
            eventData['articuloType'] = "membresías";
            eventData['subscriptionType'] = getNameMembership(product?.product_name);
            eventData['periodoPago'] = getPeriodMembership(product?.product_sku);
            eventData['source'] = 'carrito';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('eliminar_membresia_carrito', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('eliminar_membresia_carrito_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event eliminar_membresia_carrito error", e);
        }
    };

    const handleDeleteProgramEventTrack = (product) => {
        try {
            const membershipProduct = getMembershipFromCart(cartProducts);
            const eventData = {};
            eventData['nombreArticulo'] = product?.product_name;
            eventData['precioArticulo'] = 0;
            eventData['articuloType'] = "programa";
            eventData['subscriptionType'] = getNameMembership(membershipProduct?.product_name);
            eventData['programID'] = product?.product_reference_id;
            eventData['courseID'] = '';
            eventData['marcaCurso'] = '';
            eventData['source'] = 'carrito';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('eliminar_programa', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('eliminar_programa_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event eliminar_programa error", e);
        }
    };

    const handleDeleteProductEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.product_name;
            eventData['precioArticulo'] = product?.unit_price;
            eventData['articuloType'] = getProductType(product?.product_type_id);
            eventData['subscriptionType'] = getNameMembership(product?.product_name);
            eventData['courseID'] = product?.product_type_id === PRODUCT_TYPES.CURSO ? product?.product_reference_id : '';
            eventData['marcaCurso'] = product?.product_type_id === PRODUCT_TYPES.CURSO ? getCourseBrandFromUrl(product?.product_image_url) : '';
            eventData['programID'] = product?.product_type_id === PRODUCT_TYPES.PROGRAMA ? product?.product_reference_id : '';
            eventData['source'] = 'carrito';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('eliminar_producto', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('eliminar_producto_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event eliminar_producto error", e);
        }
    };

    const handleItemDeleteClick = async (product) => {
        let modalMessage = '';

        if (product.product_type_id === PRODUCT_TYPES.PROGRAMA) {
            modalMessage = getModalConfirmationMessage(PRODUCT_TYPES.PROGRAMA);
        } else if (product.product_type_id === PRODUCT_TYPES.MEMBRESIA) {
            const hasProgram = getProgramFromCart(cartProducts);
            const hasAddon = getAddonFromCart(cartProducts);
            const hasCourse = getThirdPartyCourseFromCart(cartProducts);

            if (!!hasProgram || !!hasAddon || !!hasCourse) {
                modalMessage = getModalConfirmationMessage(PRODUCT_TYPES.MEMBRESIA);
            }
        }

        if (modalMessage) {
            setModalProps({ ...modalProps, open: true, product, message: modalMessage });
        } else {
            await deleteProduct(product);
        }
    };

    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false, product: null, message: null });
    };

    const handleOnConfirmModal = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
        await deleteProduct(modalProps.product);
    };

    const getHarmonHallAddons = async (membershipSKU) => {
        try {
            setLoadingAddons(true);
            const response = await ProductServiceSDK.getHarmonHallMemberships(membershipSKU);
            if (response?.length > 0) {
                dispatch(applyAddons(response));
                setShowAddons(true); // Mostrar las membresías Harmon Hall si se cargaron los add-ons
            } else {
                dispatch(applyAddons([]));
                setShowAddons(false);
            }
            setLoadingAddons(false);
        } catch (error) {
            log("error al obtener addons", error);
        }
    };

    React.useEffect(() => {
        // Verificar si hay alguna membresía de Talisis en el carrito
        const membership = getMembershipFromCart(cartProducts);
        if (membership) {
            if (hasHarmonHallAddon !== null) {
                if (membership.product_sku !== currentMembershipSKU) {
                    setCurrentMembershipSKU(membership.product_sku);
                    // No cargar las membresías Harmon Hall si el usuario ya cuenta con una de ellas
                    if (!hasHarmonHallAddon) {
                        getHarmonHallAddons(membership.product_sku);
                    } else {
                        dispatch(applyAddons([]));
                        setShowAddons(false);
                    }
                }
            }
        } else {
            dispatch(applyAddons([]));
            setShowAddons(false);
            setCurrentMembershipSKU(null);
        }
    }, [cartProducts, hasHarmonHallAddon]);

    React.useEffect(() => {
        // Verifica si hay algún add-on en el carrito en la carga inicial
        const existingAddon = getAddonFromCart(cartProducts);
        if (existingAddon) {
            dispatch(applySelectedAddon(existingAddon.product_id));
        } else {
            dispatch(applySelectedAddon(null));
        }
    }, [cartProducts])

    const initializeCheckout = () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const isReferred = queryParams.get('isReferred') === 'true';
            const currentCheckoutCookie = getCheckoutCookie();
            setCheckoutCookie({
                ...currentCheckoutCookie,
                isReferred
            })
        } catch (e) {
            log("Error->", e);
        }
    };

    React.useEffect(() => {
        getCart()
        initializeCheckout()
    }, []);

    return <Box className={classes.cartContainer}>
        <CheckoutMaintenanceAlert display={false} />
        <CartNavigationBar showContinueShopping={false} />
        <Grid container spacing={4} className={classes.cartGrid}>
            {showAddons && (
                <Slide direction="right" in={showAddons} mountOnEnter unmountOnExit>
                    <Grid item className={classes.harmonHallContainer}>
                        <LoaderContent loading={loadingAddons} transparent={0}>
                            <HarmonHallAddons onCartLoading={setLoading} />
                        </LoaderContent>
                    </Grid>
                </Slide>
            )}
            <Grid item xs>
                <Box className={classes.cartReview}>
                    <Typography variant='h3' className="text-violet-300">Revisa tu orden</Typography>
                </Box>
                <LoaderContent loading={loading} transparent>
                    <Card variant='outlined'>
                        <CardContent className={showAddons ? classes.cartContentNarrow : classes.cartContent}>
                            <CartDetails onItemDeleteClick={handleItemDeleteClick} loading={loading} />
                            <CartPriceDetails />
                            <CartCoupon />
                            <CartPayment hasHarmonHallAddon={hasHarmonHallAddon} />
                        </CardContent>
                    </Card>
                </LoaderContent>
            </Grid>
        </Grid>
        {showDemo && <CartTest />}
        <CartProductDeleteModal {...modalProps} onClose={handleOnCloseModal} onConfirm={handleOnConfirmModal} />
    </Box>;
};

export default Cart;