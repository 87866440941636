import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import * as paths from '../../../../../../routes/paths';

// redux
import { connect } from "react-redux"

// @material-ui/core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// utils
import { userIsLogged } from "../../../../../../shared/utils/Sessions";

// @material-ui/icons
import getConnectionAllies from '../../../../../../shared/components/Allies/AlliesConnect'

import SaveUnsaveButton from '../SaveUnsaveButton/SaveUnsaveButton';

// SDK-POINT
import { ProgramService, ProductService, PRODUCT_TYPES } from '@sdk-point/talisis';

// shared components
import ModalClassEnrollment from "../../../../../../shared/components/ModalClassEnrollment/ModalClassEnrollment";
import { log } from '../../../../../../shared/utils/console.js'
import { eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker';

// ds
import { COMPANIES } from '../../../../../../design-system/constants/sources.js';

// cart
import { addProductsToCart } from '../../../../../../shared/utils/cartHelper.js';
import { applyCart, applyCartProducts } from '../../../../../../redux/actions/cartActions.js';
import { getCourseBrand } from '../../../../../../shared/utils/checkoutHelpers.js';

const PROGRAM_STYLE = 'Asincrónico';
const INSCRIBIRME_AHORA_LABEL = 'Inscribirme ahora';
const CURSO_COMPRADO_LABEL = 'Ya has comprado este curso:';
const CURSO_INSCRITO_LABEL = 'Ya estás inscrito a este curso:';
const MIS_CURSOS_LABEL = 'Ir a mis cursos';

const CourseSelling = (props) => {
    const history = useHistory();
    const location = useLocation();
    const isLogged = userIsLogged();
    const { cart } = useSelector((state) => state.cartReducer);
    const isResponsive = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [goToPurchase, setGoToPurchase] = React.useState(true);
    const { course, classes, user, membershipData, backgroundImage } = props;
    const [membershipIncluded, setMembershipIncluded] = React.useState(false);
    const [actualMembership, setActualMembership] = React.useState([]);
    const [hideInfoData, setHideInfoData] = useState(props.hideInfoData ? props.hideInfoData : false);
    const [modalProps, setModalProps] = React.useState({ open: false, loading: false, course: null, error: null });
    const [asyncEnrollType, setAsyncEnrollType] = React.useState(false);
    const [disabledCta, setDisabledCta] = React.useState(false);

    const programService = new ProgramService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    const productService = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);
    
    const handleOnCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
    };

    const handleOnRetry = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
        membershipCourseEnrollment();
    };

    const handleOnSupport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalProps({ ...modalProps, open: false });
    };

    const handleOnConfirmModal = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            setModalProps({ ...modalProps, loading: true, error: null });

            if (modalProps.course?.course_id !== null && modalProps.course?.course_session_id !== null && modalProps.course?.company_id != null) {
                const body = {
                    course_id: modalProps.course?.course_id,
                    course_session_id: modalProps.course?.course_session_id,
                    company_id: modalProps.course?.company_id,
                };

                const responseEnrollment = await programService.postPersonEnrollToClass(user.person_id, body);
                log(responseEnrollment);
                setModalProps({ ...modalProps, loading: false, open: false, error: null });
                handleContinueToClass(modalProps.course);
                history.push(paths.CURSOS);
            }
            else {
                setModalProps({ ...modalProps, loading: false, error: 1 });
            }
        } catch (e) {
            setModalProps({ ...modalProps, loading: false, error: 2 });
        }
    };

    const handleContinueToClass = async (course) => {
        const body = {
            person_id: user?.person_id,
            email: user?.email,
            ou: user?.ou,
            course_url: course.course_url,
            source_id: course.source_id,
            course_url_neo: course.course_url_neo,
            course_id: course.course_id,
            company_id: course.company_id,
            course_name: course?.name,
        }
        const win = window.open(window.location.protocol + '//' + window.location.host + '/redireccionando/' + course?.source_id, "_blank");

        var urlRespuesta = await getConnectionAllies(body);
        setTimeout(() => {
            win.focus();
            win.location.replace(urlRespuesta);
        }, 3000);
    };

    const membershipCourseEnrollment = async () => {
        try {
            setDisabledCta(true);

            let searchParams = "";
            const response = await productService.postProductEnrollment({
                person_id: user?.person_id,
                course_id: course?.id,
            });

            if (response?.success) {
                searchParams = `courseId=${course?.id}`;
                setDisabledCta(false);
                setGoToPurchase(false);
                history.replace({
                    pathname: "/confirmacion-inscripcion",
                    search: searchParams
                });
            }
            else {
                setDisabledCta(false);
                setModalProps({ ...modalProps, open: true, loading: false, error: 2 });
            }
        }
        catch (e) {
            log(e);
            setDisabledCta(false);
            setModalProps({ ...modalProps, open: true, loading: false, error: 2 });
        }
    }

    const continueToCart = async () => {
        try {
            setDisabledCta(true);
            const product = await productService.getProductByReferenceId(PRODUCT_TYPES.CURSO, course?.id);
            if (product) {
                handleCoursePurchaseEventTrack(product);
                const products = [{
                    "product_id": product.id,
                    "product_name": product.name,
                    "product_image_url": product.image_url,
                    "product_description": product.description,
                    "product_type_id": product.product_type_id,
                    "quantity": 1,
                    "product_price_id": product.product_price_id,
                    "unit_price": product.list_price,
                    "discount": 0
                }];
                let response = await addProductsToCart({
                    cart_id: cart?.id,
                    products: [...products]
                });
                // Actualizar estado reducer del carrito
                dispatch(applyCart(response));
                dispatch(applyCartProducts(response.cart_details));
                // Redireccionar al carrito para ver el detalle.
                history.push(paths.CART);
            }
        } catch (e) {
            log("error", e);
        }
    }

    const preHandleCheckout = (e) => {
        e.preventDefault();
        if (asyncEnrollType) {
            setModalProps({ ...modalProps, open: true, loading: false, error: null });
        } else {
            handleCheckout(e);
        }
    };

    const handleCheckout = (e) => {
        e.preventDefault();

        if (actualMembership != undefined) {
            if ((isLogged && membershipIncluded)) {
                membershipCourseEnrollment();
            } else if (isLogged && course?.price === 0) {
                membershipCourseEnrollment();
            } else if (isLogged && !membershipIncluded) {
                continueToCart();
            } else {
                continueToCart();
            }
        } 
        else {
            if (course?.user_membership_included) {
                membershipCourseEnrollment();
            } else if (course?.price === 0) {
                if (isLogged) {
                    membershipCourseEnrollment();
                } else {
                    history.push(paths.SIGNUP);
                }
            } else {
                continueToCart();
            }
        }
    };

    const handleCoursePurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "curso";
            eventData['marcaCurso'] = getCourseBrand(course?.source_id, course?.company_id);
            eventData['subscriptionType'] = "freemium";
            eventData['courseID'] = course?.id;
            eventData['programID'] = '';
            eventData['source'] = 'cursos';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_curso', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_curso_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("agregar_curso event error", e);
        }
    };

    const gotoMemberships = (e) => {
        e.preventDefault();
        //
        const eventData = {};
        eventData['source'] = 'curso';
        eventTracker('ver_membresías', eventData, ['mixpanel']);
        //
        history.push(`${paths.MEMBRESIAS}?sku=${course.product_sku}`);
    };

    const handleClickContinue = async (e) => {
        e.preventDefault();

        history.push(`/cursos`);
    }

    const dataComprobation = async () => {
        (course?.is_purchased === 1 || course?.is_enrolled === 1) && setGoToPurchase(false);

        //MEMBERSHIP INCLUDED PARA OFRECER SOLAMENTE LA SIGUIENTE MEMBRESÍA CUANDO EL CURSO NO ESTÁ INCLUIDO EN LA MEMBRESÍA ACTUAL
        setMembershipIncluded(course?.user_membership_included ? true : false);
        setActualMembership(membershipData?.user_membership?.actual_membership ? membershipData.user_membership.actual_membership : null);
    }

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dataComprobation();
        }
        //
        const params = new URLSearchParams(location.search);
        if (params.get('tipo_inscripcion') === PROGRAM_STYLE && location?.state?.enrollment?.remainingCredits > 0) {
            setAsyncEnrollType(true);
            setModalProps({ course: location.state.enrollment.course, error: null });
        }
        //
        return () => { isMounted = false };
    }, []);

    const getActionButton = () => {
        if (disabledCta) {
            return <CircularProgress size={16} />
        }
        if (!isLogged) {
            return INSCRIBIRME_AHORA_LABEL;
        }
        if (!goToPurchase) {
            return MIS_CURSOS_LABEL;
        }
        if (course?.price > 0) {
            return actualMembership?.sidebar_paid?.action_button;
        }
        if (course?.price === 0) {
            return INSCRIBIRME_AHORA_LABEL;
        } 
        return INSCRIBIRME_AHORA_LABEL;
    };

    return (
        <React.Fragment>
            {
                course?.price > 0 && !course?.user_membership_included && goToPurchase && <Box display="flex" className='mt-2' style={{ marginBottom: goToPurchase ? "15px" : "5px" }}>
                    <Box className={classes.boxPrice}>
                        <Typography variant="caption" className='text-gray-500'>{goToPurchase ? "Obtenlo por:" : CURSO_COMPRADO_LABEL}</Typography>
                        <Typography variant="h3" color={!hideInfoData ? "primary" : "secondary"} className={classes.price}>
                            {goToPurchase && <CurrencyFormat value={course?.price} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator={true} prefix="$" suffix=" MXN" />}
                        </Typography>
                    </Box>
                </Box>
            }
            {
                course?.price === 0 &&
                <Typography variant="h3"
                    color={!hideInfoData ? "primary" : "secondary"}
                    className={clsx(classes.price, classes.priceFree, { 'offset-pb-5': !hideInfoData })}
                    style={{ marginBottom: isResponsive ? 8 : 24 }}
                >
                    Curso gratis
                </Typography>
            }
            {
                !goToPurchase ?
                    course?.is_purchased === 1 ?
                        <Box className='mb-2'>
                            <Typography variant="caption" color="textSecondary">{CURSO_COMPRADO_LABEL}</Typography>
                        </Box> :
                        <Box className='mb-2'>
                            <Typography variant="caption" color="textSecondary">{CURSO_INSCRITO_LABEL}</Typography>
                        </Box> :
                    null
            }
            <Box display="flex">
                <Box display="flex" style={{ position: "relative", width: isLogged && !hideInfoData ? "85%" : "100%" }}>
                    <Button variant="contained" color='primary' fullWidth onClick={(e) => goToPurchase ? preHandleCheckout(e) : handleClickContinue(e)} style={{ textTransform: 'none' }} disabled={!actualMembership || disabledCta}>
                        {getActionButton()}
                    </Button>
                </Box>
                {isLogged && !hideInfoData && !disabledCta && <SaveUnsaveButton course={course} classes={classes.markerButton} white={true} />}
            </Box>
            {
                actualMembership != undefined && goToPurchase && !hideInfoData ?
                    <Box>
                        <Box style={{ marginBottom: '12px', marginTop: '36px' }} hidden={actualMembership?.slug !== "freemium"}>
                            <Grid container>
                                <Grid item xs style={{ paddingTop: '7px' }}><Divider /></Grid>
                                <Grid item xs="auto" style={{ paddingLeft: '8px', paddingRight: '8px' }}>O</Grid>
                                <Grid item xs style={{ paddingTop: '7px' }}><Divider /></Grid>
                            </Grid>
                        </Box>
                        <Box style={{ marginBottom: '8px', marginTop: '24px' }} hidden={actualMembership?.sidebar_paid?.title === ""}>
                            <Typography className="font-variant-v7">{actualMembership?.sidebar_paid?.title}</Typography>
                        </Box>
                        <Box style={actualMembership?.sidebar_paid?.title === "" ? { marginBottom: '24px', marginTop: '24px' } : { marginBottom: '24px' }} hidden={actualMembership?.sidebar_paid?.description === ""}>
                            <Typography className='font-variant-v2'>{actualMembership?.sidebar_paid?.description_alt != undefined && course?.company_id === COMPANIES.UERRE ? actualMembership?.sidebar_paid?.description_alt : actualMembership?.sidebar_paid?.description}</Typography>
                        </Box>
                        {
                            actualMembership?.sidebar_paid?.memberships_button !== "" ?
                                <Box display="flex">
                                    <Box display="flex" style={{ width: "100%" }}>
                                        <Button variant="outlined" color='secondary' fullWidth onClick={(e) => gotoMemberships(e)} style={{ textTransform: 'none' }}>
                                            {actualMembership?.sidebar_paid?.memberships_button}
                                        </Button>
                                    </Box>
                                </Box> : null
                        }
                    </Box> : null
            }
            {
                !hideInfoData &&
                <Box display="flex" flexDirection="column" className='mt-4 mb-2' alignItems="flex-start" style={{ maxHeight: "175px", overflow: 'hidden', borderRadius: '8px' }}>
                    <img src={backgroundImage} alt={course.name} style={{ width: "100%" }} />
                </Box>
            }
            <ModalClassEnrollment {...modalProps} onClose={handleOnCloseModal} onConfirm={handleOnConfirmModal} onRetry={handleOnRetry} onSupport={handleOnSupport} />
        </React.Fragment>
    )
};

const mapStateToProps = (reducers) => reducers.userReducer;

export default connect(mapStateToProps, {})(CourseSelling);