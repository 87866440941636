import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

// @material-ui
import {
    Card,
    CardContent,
    Grid,
    Hidden,
    Typography
} from "@material-ui/core";

// assets
import useStyles from '../../../assets/styles/jss/pages/cart/cartStyle.js';

// shared
import { log } from '../../../shared/utils/console.js';
import { userIsLogged } from '../../../shared/utils/Sessions.js';

// core components
import HarmonHallHeader from './HarmonHallHeader/HarmonHallHeader.jsx';
import HarmonHallCard from './HarmonHallCard/HarmonHallCard.jsx';
import HarmonHallCarousel from './HarmonHallCarousel/HarmonHallCarousel.jsx';

// cart
import { addProductsToCart } from '../../../shared/utils/cartHelper.js';
import { applyCart, applyCartProducts, applySelectedAddon } from '../../../redux/actions/cartActions.js';
import { eventTracker } from '../../../shared/components/EventTracker/EventTracker.js';
import { getNameMembership } from '../../../shared/utils/checkoutHelpers.js';
import { getMembershipFromCart } from '../utils/getMembershipFromCart.js';

const HarmonHallAddons = ({ onCartLoading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLogged = userIsLogged();
    const { cart, cartProducts, addons, selectedAddon } = useSelector((state) => state.cartReducer);
    const { user } = useSelector((state) => state.userReducer);

    const handleCardClick = async (e, product) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedAddon) {
            return; // Evita que se seleccione un nuevo add-on si ya hay uno seleccionado
        }

        try {
            // Iniciar loading del carrito
            onCartLoading(true);
            // Preparar el producto 
            const productAddOn = ({
                "product_id": product?.id,
                "product_name": product?.name,
                "product_image_url": product?.image_url,
                "product_description": product?.description,
                "product_type_id": product?.product_type_id,
                "quantity": 1,
                "product_price_id": product?.product_price_id,
                "unit_price": product?.list_price,
                "discount": 0
            });
            const products = [productAddOn];
            // Event tracker membresía + addon
            handleMembershipAddonEventTrack(productAddOn);
            // Enviar producto add-on al carrito
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products]
            });
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            // Establecer el add-on seleccionado
            dispatch(applySelectedAddon(product?.id))
            // Actualizar loading del carrito
            onCartLoading(false);
        } catch (e) {
            log("error", e);
        }
    };

    const handleMembershipAddonEventTrack = (productAddon) => {
        try {
            // Consultar membresía del carrito
            const product1 = getMembershipFromCart(cartProducts);
            const eventData = {};
            eventData['nombreArticulo'] = productAddon?.product_name;
            eventData['precioArticulo'] = productAddon?.unit_price + product1?.unit_price;
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(product1?.product_name);
            eventData['source'] = 'carrito';
            if (isLogged) {
                eventData['userId'] = user?.matricula || user?.person_id || "";
                eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
            } else {
                eventTracker('agregar_membresia_addon_anonimo', eventData, ['mixpanel', 'google']);
            }
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    return (
        <Card variant='outlined'>
            <CardContent className={classes.cartHarmonHall}>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <HarmonHallHeader />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" className='mb-1 text-hh-yellow'>Aprende Inglés<br />ahorrando hasta el 80%</Typography>
                        <Typography variant="h4" className=''>Con tu membresía Talisis</Typography>
                    </Grid>
                    <Hidden xsDown>
                        {addons?.map((product, index) => {
                            return <Grid item key={index}>
                                <HarmonHallCard
                                    product={product}
                                    onCardClick={handleCardClick}
                                    selectedAddonId={selectedAddon} />
                            </Grid>
                        })}
                    </Hidden>
                    <Hidden smUp>
                        <Grid item className={classes.harmonHallCarousel}>
                            <HarmonHallCarousel onCardClick={handleCardClick} />
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Typography variant="caption" color="">Obtén tu membresía de Harmon Hall Online a precio preferencial en la compra de tu membresía Talisis</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default HarmonHallAddons;