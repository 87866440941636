import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import moment from "moment";
import { Box, Icon, Typography, IconButton, Collapse, Hidden, Button } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root:{
        background: theme.palette.colors.electricBlue[500],
        display: 'flex',
        height: 68,
        padding: '10px 23px 11px 23px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gridGap: 120,
        flexShrink: 0
    },
    boxContent:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gridGap:80
    },
    time:{
        display:'flex',
        alignItems:'center',
        gridGap:8
    },
    title:{
        display:'flex',
        alignItems:'center',
        gridGap:16
    },
    code:{
        display:'flex',
        alignItems:'center',
        gridGap:16,
        '& .code':{
            borderRadius: 40,
            padding: '4px 16px',
            background: theme.palette.white
        }
    },
    timeContent:{
        display:'flex',
        gridGap:32
    },
    [theme.breakpoints.down('md')]:{
        root:{
            gridGap: 60,
        },
        boxContent:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            gridGap:16
        }
    },
    [theme.breakpoints.down('sm')]:{
        root:{
            justifyContent: 'normal',
            height: 'fit-content',
            gridGap: 8,
            padding: 16,
            alignItems: 'normal',
        },
        boxContent:{
            gridGap:8,
            flexDirection: 'column',
        },
        codeContent:{
            width: '100%',
            order: 3,
            display:'flex',
            justifyContent:'space-between',
            '& p, h4':{
                fontSize: 12,
                lineHeight: '12px'
            }
        },
        title:{
            order: 2
        },
        time:{
            gridGap:2,
            width: '100%'
        },
        code:{
            gridGap:8,
        },
        timeContent:{
            width: '100%'
        }
    },
    [theme.breakpoints.down('xs')]:{
        root:{
            // height: 'fit-content',
            // gridGap: 8,
            // padding: 16,
            // alignItems: 'normal',
        },
        boxContent:{
            gridGap:8,
            flexDirection: 'column',
            width: 304
        },
        codeContent:{
            width: '100%',
            order: 3,
            display:'flex',
            justifyContent:'space-between',
            '& p, h4':{
                fontSize: 12,
                lineHeight: '12px'
            }
        },
        title:{
            order: 2,
            width: '100%'
        },
        time:{
            gridGap:2,
            width: '100%'
        },
        code:{
            gridGap:8,
        },
        timeContent:{
            width: '100%'
        }
    }
}));

const CountdownHotSale = ({open = true, onClose}) => {
    const classes = useStyles();
    const { user } = useSelector((state) => ({...state.userReducer}));
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);
    
        return () => clearInterval(interval);
    }, []);

    const targetDate = moment("2024-09-18");
    const timeBetween = moment.duration(targetDate.diff(currentTime));

    let days = timeBetween.days().toString().padStart(2, "0");
    let hours = timeBetween.hours().toString().padStart(2, "0");
    let minutes = timeBetween.minutes().toString().padStart(2, "0");
    let seconds = timeBetween.seconds().toString().padStart(2, "0");

    if (timeBetween.asMilliseconds() <= 0) {
        days = hours = minutes = seconds = "00";
    }

    if(user.person_id){
        return null
    }

    const HotCode = () => <Box className={classes.code}>
        <Typography variant="body2" className="semi-bold">código</Typography>
        <Box className="code">
            <Typography variant="h4" className="text-electric-blue-300">BACK2024</Typography>
        </Box>
    </Box>

    return (
        <Collapse in={open}>
            <Box className={classes.root}>
                <Box className={classes.boxContent}>
                    <Hidden mdUp>
                        <Box width={1} pl='56px'>
                            <Typography variant='caption' className="semi-bold">Quedan:</Typography>
                        </Box>
                    </Hidden>
                    <Box className={classes.title}>
                        <span className="d-md-none" style={{fontSize: 40}}>🤓</span>
                        <Box>
                            <Typography variant="h4" className="font-size-20">
                            20% de descuento todo un año
                            </Typography>
                            <Typography >
                            ¡Back to School cuando TÚ quieras!
                            </Typography>
                        </Box>
                    </Box>

                    <Hidden only={'sm'}>
                        <Box className={classes.codeContent}>
                            <HotCode />
                            <Hidden smUp>
                                <Button variant='contained' size='small' href="https://www.talisis.com/membresias?utm_source=website&utm_medium=banner&utm_campaign=hot_days">Empieza ya</Button>
                            </Hidden>
                        </Box>
                    </Hidden>

                    <Box className={classes.timeContent}>
                        <Box className={classes.time}>
                            <Hidden mdUp>
                                <Box width={40} height={40} px={.5} display='flex' alignItems='center' justifyContent='center'>
                                    <span className="font-size-24">🤯</span>
                                </Box>
                            </Hidden>
                            <Hidden smDown>
                                <Typography variant='button'>Quedan:</Typography>
                            </Hidden>
                            <Box textAlign='center' width={50}>
                                <Typography variant="h3">{days}</Typography>
                                <Typography variant="caption">días</Typography>
                            </Box>
                            <Icon className="ri-checkbox-blank-circle-fill text-white" style={{fontSize: 4}}/>
                            <Box textAlign='center' width={50}>
                                <Typography variant="h3">{hours}</Typography>
                                <Typography variant="caption">horas</Typography>
                            </Box>
                            <Icon className="ri-checkbox-blank-circle-fill text-white" style={{fontSize: 4}}/>
                            <Box textAlign='center' width={50}>
                                <Typography variant="h3">{minutes}</Typography>
                                <Typography variant="caption">min</Typography>
                            </Box>
                            <Icon className="ri-checkbox-blank-circle-fill text-white" style={{fontSize: 4}}/>
                            <Box textAlign='center' width={50}>
                                <Typography variant="h3">{seconds}</Typography>
                                <Typography variant="caption">seg</Typography>
                            </Box>
                        </Box>
                        <Hidden mdDown>
                            <Button variant='contained' size='small' href="https://www.talisis.com/membresias?utm_source=website&utm_medium=banner&utm_campaign=hot_days">Empieza ya</Button>
                        </Hidden>
                    </Box>

                </Box>
                <Hidden only={['lg', 'md', 'xl', 'xs']}>
                    <Box marginLeft='auto' display='flex' flexDirection='column' justifyContent='space-between'>
                        <HotCode />
                        <Box textAlign='end'>
                            <Button variant='contained' size='small' href="https://www.talisis.com/membresias?utm_source=website&utm_medium=banner&utm_campaign=hot_days">Empieza ya</Button>
                        </Box>
                    </Box>
                </Hidden>
                
                <Box>
                    <IconButton className='p-0' aria-label="close" onClick={() => onClose()}>
                        <Icon className='ri-close-circle-fill text-white font-size-16' />
                    </IconButton>
                </Box>
            </Box>
        </Collapse>
    );
};

export default CountdownHotSale;
