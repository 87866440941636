import * as checkoutTypes from '../types/checkoutTypes';

export const setOrder = (order) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER,
        payload: order
    })
};

export const setOrderProducts = (products) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_PRODUCTS,
        payload: products
    })
};

export const setPersonInfo = (personInfo) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_PERSON_INFO,
        payload: personInfo
    })
};

export const setOrderPriceDetails = (priceDetails) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_PRICE_DETAILS,
        payload: priceDetails
    })
};

export const setInstallmentOptions = (installmentOptions) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_INSTALLMENTS_OPTIONS,
        payload: installmentOptions
    })
};

export const setSelectedInstallmentOption = (installmentOption) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_SELECTED_INSTALLMENT_OPTION,
        payload: installmentOption
    })
};

export const setOrderTotal = (total) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_TOTAL,
        payload: total
    })
};

export const setOrderSubtotal = (subtotal) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_SUBTOTAL,
        payload: subtotal
    })
};

export const setOrderBonuses = (bonuses) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_BONUSES,
        payload: bonuses
    })
};

export const setOrderDiscounts = (discounts) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_DISCOUNTS,
        payload: discounts
    })
};

export const setOrderSavings = (savings) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_SAVINGS,
        payload: savings
    })
};

export const setOrderNextPaymentDate = (nextPaymentDate) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_ORDER_NEXT_PAYMENT_DATE,
        payload: nextPaymentDate
    })
};

export const setUserValidations = (userValidations) => (dispatch) => {
    dispatch({
        type: checkoutTypes.APPLY_USER_VALIDATIONS,
        payload: userValidations
    })
};