import React from 'react';
import CurrencyFormat from 'react-currency-format';
import clsx from 'clsx';

// @material-ui
import {
    Box,
    Divider,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    caption: {
        lineHeight: '16px',
        color: theme.palette.colors.darkBlack[100],
    },
    specialCaption: {
        lineHeight: '16px',
        color: theme.palette.colors.orange[300],
        fontWeight: '600'
    },
}));

const MembershipAddOn = ({ addOn }) => {
    const classes = useStyles();
    const membershipAddon = addOn?.name?.replace(/\s*\(.*?\)\s*/g, '');
    const membershipPeriod = addOn?.membership_term_months > 1 ? `por ${addOn?.membership_term_months} meses` : "al mes";

    return (
        <Box>
            <Divider component='div' className='bg-fill-stroke-200 my-1' style={{ width: '100%' }} />
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5" className='font-weight-700 my-1'>+ {membershipAddon}</Typography>
                </Grid>
                <Grid item style={{ alignSelf: "center" }}>
                    <Box>
                        <Typography variant="body1" component='span' className={clsx(classes.specialCaption)}><CurrencyFormat value={addOn?.list_price} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" /></Typography>
                        <Typography variant="body2" component='span' className={clsx(classes.caption, "ml-1")}>{membershipPeriod}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MembershipAddOn;