import React from 'react';
import clsx from 'clsx';
import moment from 'moment/moment';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// @material-ui/core
import { Button, makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// utils
import calcDaysBetweenDates from '../../../../../../shared/utils/calcDaysBetweenDates';
import dateFormat from '../../../../../../design-system/utils/dateFormat';
import { log } from '../../../../../../shared/utils/console.js'
import { COMPLETE_DATE_FORMAT } from '../../../../../../design-system/constants/date-formats';
import { getNameMembership, getPeriodMembership } from '../../../../../../shared/utils/checkoutHelpers.js';

// routes
import * as paths from '../../../../../../routes/paths';

// SDK
import { ProductService } from '@sdk-point/talisis';

// Cart
import { addProductsToCart } from '../../../../../../shared/utils/cartHelper.js';

// Redux
import { applyCart, applyCartProducts } from '../../../../../../redux/actions/cartActions.js';

// DS
import LoaderContent from '../../../../../../design-system/components/Loader/LoaderContent.jsx';

// shared
import { eventTracker } from '../../../../../../shared/components/EventTracker/EventTracker.js';

// core
import MembershipAddOn from './MembershipAddOn/MembershipAddOn.jsx';

const ProductServiceSDK = new ProductService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "212px",
        position: 'relative',
        height: '100%'
    },
    caption: {
        lineHeight: '16px',
        color: theme.palette.colors.darkBlack[100],
    },
    specialCaption: {
        lineHeight: '16px',
        color: theme.palette.colors.orange[300],
        fontWeight: '600'
    },
    freemiumBenefitsButton: {
        bottom: '2.4vw',
        left: '1vw',
        position: 'absolute',
    },
    readMoreButton: {
        padding: '0px'
    },
    arrowReadMoreButton: {
        marginTop: '0px !important',
        marginLeft: '-2px !important'
    },
    benefitsTitle: {
        ...theme.typography.bodyTextXsmallSemiBold
    },
    benefitsItem: {
        ...theme.typography.bodyTextXsmallSemiBold,
        fontWeight: 400,
    },
    cardContentGrid: {
        height: '100%'
    }
}));

const getMembershipPlanTerm = (id, plan) => id === "freemium" ? "siempre" : plan?.termMonths > 1 ? `por ${plan?.termMonths} meses` : "al mes";

const getMembershipIcon = (id, size = 20) => {
    if (id === "lite" || id === "lite-plus") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
                <path d="M10 0L11.5577 4.4762C11.9999 5.74708 12.221 6.38253 12.7285 6.7511C13.236 7.11967 13.9087 7.13336 15.254 7.16075L20 7.25735L16.2209 10.1192C15.147 10.9326 14.61 11.3393 14.4159 11.9364C14.2218 12.5335 14.417 13.1781 14.8076 14.4675L16.1803 19L12.283 16.291C11.1789 15.5235 10.6268 15.1398 10 15.1398C9.37319 15.1398 8.82112 15.5235 7.71699 16.291L3.81966 19L5.19243 14.4675C5.58295 13.1781 5.77821 12.5335 5.58413 11.9364C5.39004 11.3393 4.85305 10.9326 3.77905 10.1192L0 7.25735L4.74595 7.16075C6.09131 7.13336 6.76399 7.11967 7.27148 6.7511C7.77897 6.38253 8.00009 5.74708 8.44234 4.4762L10 0Z" fill="url(#paint0_linear_638_161)" />
                <defs>
                    <linearGradient id="paint0_linear_638_161" x1="31.3031" y1="48.7228" x2="2.8953" y2="46.803" gradientUnits="userSpaceOnUse">
                        <stop offset="0.583333" stop-color="#FCDC43" />
                        <stop offset="0.895833" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        )
    }

    if (id === "professional") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
                <path d="M10 0L10.8847 3.64386C11.18 4.85978 11.3276 5.46774 11.7968 5.6621C12.2661 5.85647 12.8003 5.53096 13.8689 4.87993L17.0711 2.92893L15.1201 6.13113C14.469 7.19967 14.1435 7.73394 14.3379 8.20318C14.5323 8.67243 15.1402 8.82004 16.3561 9.11527L20 10L16.3561 10.8847C15.1402 11.18 14.5323 11.3276 14.3379 11.7968C14.1435 12.2661 14.469 12.8003 15.1201 13.8689L17.0711 17.0711L13.8689 15.1201C12.8003 14.469 12.2661 14.1435 11.7968 14.3379C11.3276 14.5323 11.18 15.1402 10.8847 16.3561L10 20L9.11527 16.3561C8.82004 15.1402 8.67243 14.5323 8.20318 14.3379C7.73394 14.1435 7.19967 14.469 6.13113 15.1201L2.92893 17.0711L4.87993 13.8689C5.53096 12.8003 5.85647 12.2661 5.6621 11.7968C5.46774 11.3276 4.85978 11.18 3.64386 10.8847L0 10L3.64386 9.11527C4.85978 8.82004 5.46774 8.67243 5.6621 8.20318C5.85647 7.73394 5.53096 7.19967 4.87993 6.13113L2.92893 2.92893L6.13113 4.87993C7.19967 5.53096 7.73394 5.85647 8.20318 5.6621C8.67243 5.46774 8.82004 4.85978 9.11527 3.64386L10 0Z" fill="url(#paint0_linear_462_551)" />
                <defs>
                    <linearGradient id="paint0_linear_462_551" x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stop-color="#FCDC43" />
                        <stop offset="1" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (id === "premium") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
                <path d="M10 0L12.1194 5.56588C12.4337 6.39129 12.5908 6.804 12.8934 7.10659C13.196 7.40917 13.6087 7.56632 14.4341 7.88063L20 10L14.4341 12.1194C13.6087 12.4337 13.196 12.5908 12.8934 12.8934C12.5908 13.196 12.4337 13.6087 12.1194 14.4341L10 20L7.88063 14.4341C7.56633 13.6087 7.40917 13.196 7.10659 12.8934C6.804 12.5908 6.39129 12.4337 5.56588 12.1194L0 10L5.56588 7.88063C6.39129 7.56633 6.804 7.40917 7.10659 7.10659C7.40917 6.804 7.56632 6.39129 7.88063 5.56588L10 0Z" fill="url(#paint0_linear_462_142)" />
                <defs>
                    <linearGradient id="paint0_linear_462_142" x1="31.3031" y1="51.2871" x2="2.8827" y2="49.4625" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0520833" stop-color="#FCDC43" />
                        <stop offset="1" stop-color="#FFA700" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return null;
}


const MembershipCardContent = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const { cart } = useSelector((state) => state.cartReducer);
    const { id, name, selected, plan, userAddOn, user, setCancelMode } = props;
    log("usuario: ", user);

    const MembershipBenefits = {
        "freemium": <>
            <Typography className={classes.benefitsTitle}>
                Beneficios:
            </Typography>
            <ul>
                <Typography className={classes.benefitsItem}><li>Accede a cursos limitados a tu tiempo y a tu ritmo.</li></Typography>
                <Typography className={classes.benefitsItem}><li>Evaluación de habilidades y competencias: Básicas</li></Typography>
                <Typography className={classes.benefitsItem}><li>Tablero de habilidades</li></Typography>
                <Typography className={classes.benefitsItem}><li>Soporte administrativo y técnico</li></Typography>
            </ul>
        </>,
        "lite": "Accede de forma ilimitada a cursos de LinkedIn Learning y Crehana, y recibe un diploma digital por curso. Además accede a nuestra bolsa de trabajo. Adicional a todos los beneficios de Freemium.",
        "professional": "Cursa una licenciatura o maestría de UNID, con validez oficial por la SEP, en línea y a tu ritmo. Adicional a todos los beneficios de la membresía Lite.",
        "premium": "Titúlate de un MBA de U-ERRE con validez oficial, en línea y a tu ritmo. Además accede sin límite a cursos de Coursera. Adicional a todos los beneficios de la membresía Professional."
    };
    
    const term = getMembershipPlanTerm(id, plan);
    const icon = getMembershipIcon(id);
    const expiredIcon = plan.expiredUserMembership ? getMembershipIcon(plan.expiredUserMembership.slug, 14) : null;
    log("expired: ", plan.expiredUserMembership);
    const PAYMENT_TYPE = {
        CARD: 'card',
        SPEI: 'spei',
        CASH: 'cash'
    };
    const SPEI_FIRST_NOTIFICATION_DAYS = 0;
    const CASH_FIRST_NOTIFICATION_DAYS = 0;
    const CARD_FIRST_NOTIFICATION_DAYS = 0;

    const handleCancel = () => {
        setCancelMode(true);
    };

    const handleReadMore = () => {
        history.push({
            pathname: paths.MEMBRESIAS,
            state: {
                productId: 0,
                productTypeId: "",
                membershipId: 0,
                membershipFeeId: 0,
                membershipLevel: 0,
            }
        });
    };

    const handleRepurchase = async () => {
        log("plan:", plan);
        const { productId } = plan;
        try {
            setLoading(true);
            const productInfo = await ProductServiceSDK.getProductById(productId);
            log("productInfo:", productInfo);
            // Preparar los productos para la recompra
            const products = [];
            products.push({
                "product_id": productInfo.id,
                "product_name": productInfo.name,
                "product_image_url": productInfo?.image_url,
                "product_description": productInfo?.description,
                "product_type_id": productInfo?.product_type_id,
                "quantity": 1,
                "product_price_id": productInfo?.product_price_id,
                "unit_price": productInfo?.list_price,
                "discount": 0
            });
            // Agregar el add-on a la recompra si el usuario ya cuenta con uno 
            if (userAddOn) {
                products.push({
                    "product_id": userAddOn.id,
                    "product_name": userAddOn.name,
                    "product_image_url": userAddOn.image_url,
                    "product_description": userAddOn.description,
                    "product_type_id": userAddOn.product_type_id,
                    "quantity": 1,
                    "product_price_id": userAddOn.product_price_id,
                    "unit_price": userAddOn.list_price,
                    "discount": 0
                });
            }
            // Agregar productos carrito
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products]
            });
            // Event Tracker v2
            if (userAddOn) {
                handleMembershipAddonEventTrack(productInfo, userAddOn);
            } else {
                handleMembershipRepurchaseEventTrack(productInfo);
            }
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            setLoading(false);
            // Redireccionar al carrito para ver el detalle.
            history.push(paths.CART);
        } catch (e) {
            log("error", e);
        }
    };

    const handleMembershipRepurchaseEventTrack = (product) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = product?.name;
            eventData['precioArticulo'] = product?.list_price;
            eventData['articuloType'] = "membresías";
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['subscriptionType'] = getNameMembership(product?.name);
            eventData['periodoPago'] = getPeriodMembership(product?.sku);
            eventTracker('agregar_membresia', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event tracker error", e);
        }
    };

    const handleMembershipAddonEventTrack = (standalone, addOn) => {
        try {
            const eventData = {};
            eventData['nombreArticulo'] = addOn?.name;
            eventData['precioArticulo'] = addOn?.list_price + standalone?.list_price;
            eventData['userId'] = user?.matricula || user?.person_id || "";
            eventData['articuloType'] = "addOn"
            eventData['subscriptionType'] = getNameMembership(standalone?.name);
            eventTracker('agregar_membresia_addon', eventData, ['mixpanel', 'hubspot', 'google']);
        } catch (e) {
            log("event agregar_membresia_addon error", e);
        }
    };

    /* Fase 1: Upgrade solo de la membresía, add-ons quedan pendientes */
    const handleUpgrade = async () => {
        log("plan:", plan);
        const { productId } = plan;
        try {
            setLoading(true);
            const productInfo = await ProductServiceSDK.getProductById(productId);
            log("productInfo:", productInfo);
            const products = [];
            products.push({
                "product_id": productInfo.id,
                "product_name": productInfo.name,
                "product_image_url": productInfo?.image_url,
                "product_description": productInfo?.description,
                "product_type_id": productInfo?.product_type_id,
                "quantity": 1,
                "product_price_id": productInfo?.product_price_id,
                "unit_price": productInfo?.list_price,
                "discount": 0
            });
            // Agregar productos carrito
            const response = await addProductsToCart({
                cart_id: cart?.id,
                products: [...products]
            });
            // Event Tracker v2
            handleMembershipRepurchaseEventTrack(productInfo);
            // Actualizar estado reducer del carrito
            dispatch(applyCart(response));
            dispatch(applyCartProducts(response.cart_details));
            setLoading(false);
            // Redireccionar al carrito para ver el detalle.
            history.push(paths.CART);
        } catch (e) {
            log("error", e);
        }
    };

    const cancelMembershipsButtonLabel = id !== "freemium" && userAddOn ? 'Cancelar suscripciones' : 'Cancelar membresía';
    const renewMembershipsButtonLabel = id !== "freemium" && userAddOn ? 'Renovar membresías' : 'Renovar membresía';

    return (
        <LoaderContent loading={loading} transparent={0}>
            <Box className={clsx(classes.root, "p-3")}>
                <Grid container direction='column' justifyContent='space-between' className={classes.cardContentGrid}>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Grid container spacing={1}>
                                    {icon && <Grid item style={{ display: "flex" }}>{icon}</Grid>}
                                    <Grid item style={{ alignSelf: "center" }}><Typography variant="h5">{name}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ alignSelf: "center" }}>
                                <Grid container alignItems="baseline">
                                    {
                                        id === "freemium" ?
                                            <Grid item><Typography variant="h5" color="initial" component="span">Gratis</Typography></Grid> :
                                            (
                                                !plan.isNextMembership && !plan.isExpiredMembership ?
                                                    <>
                                                        <Grid item><Typography variant="body1" className={clsx(classes.specialCaption)}><CurrencyFormat value={plan.amount} decimalScale={0} fixedDecimalScale={true} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" /></Typography></Grid>
                                                        <Grid item><Typography variant="body2" className={clsx(classes.caption, "ml-1")}>{term}</Typography></Grid>
                                                    </> :
                                                    <>
                                                        <Grid item><Typography variant="body2" className={clsx(classes.caption, "mr-1")}>Desde</Typography></Grid>
                                                        <Grid item><Typography variant="body1" className={clsx(classes.specialCaption)}><CurrencyFormat value={plan.cheapestPricePerMonth} decimalScale={0} fixedDecimalScale={false} displayType="text" thousandSeparator="," prefix="$" suffix=" MXN" /></Typography></Grid>
                                                        <Grid item><Typography variant="body2" className={clsx(classes.caption, "ml-1")}>al mes</Typography></Grid>
                                                    </>
                                            )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {selected && id !== "freemium" && userAddOn && <MembershipAddOn addOn={userAddOn} />}
                        <Grid container>
                            <Grid item>
                                {
                                    selected && id !== "freemium" ?
                                        <Box>
                                            <Box className="mt-3">
                                                <Typography variant="body1" className={clsx({ "font-weight-600": true, "text-electric-red-300": plan.pastDue })} color="primary">
                                                    {plan.nextBillingDate ? (plan.pastDue ? '0 días restantes' : `${calcDaysBetweenDates(moment(), plan.nextBillingDate)} días restantes`) : null}
                                                </Typography>
                                                <Typography variant="caption" className='text-dark-black-100'>
                                                    {plan.nextBillingDate ? (plan.pastDue ? `Tu fecha de facturación fue el ${dateFormat(plan.nextBillingDate, COMPLETE_DATE_FORMAT)}` : `Tu próxima fecha de facturación es el ${dateFormat(plan.nextBillingDate, COMPLETE_DATE_FORMAT)}`) : null}
                                                </Typography>
                                            </Box>
                                        </Box> :
                                        <Box>
                                            <Box className="mt-3">
                                                <Typography variant="caption">{MembershipBenefits[id]}</Typography>
                                            </Box>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            selected && id !== "freemium" ?
                                <Box>
                                    <Box className="mt-4">
                                        <Grid container spacing={0} alignItems='center' justifyContent='space-between'>
                                            <Grid item>
                                                <Button variant="text" className={clsx("px-2", "py-2")} onClick={handleCancel}>
                                                    <Typography variant='body2' className='text-gray-400 font-weight-600'>{cancelMembershipsButtonLabel}</Typography>
                                                </Button>
                                            </Grid>
                                            {((plan.paymentType === PAYMENT_TYPE.SPEI && plan.remainingDaysBeforeExpired < SPEI_FIRST_NOTIFICATION_DAYS) || (plan.paymentType === PAYMENT_TYPE.CASH && plan.remainingDaysBeforeExpired < CASH_FIRST_NOTIFICATION_DAYS) || (plan.paymentType === PAYMENT_TYPE.CARD && plan.remainingDaysBeforeExpired < CARD_FIRST_NOTIFICATION_DAYS)) &&
                                                <Grid item>
                                                    <Button variant="contained" color="primary" onClick={handleRepurchase}>{renewMembershipsButtonLabel}</Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Box> :
                                <Box>
                                    {
                                        id !== "freemium" && <Box className="mt-4">
                                            <Grid container spacing={0} alignItems='center' justifyContent='space-between'>
                                                <Grid item>
                                                    {
                                                        plan.isExpiredMembership ?
                                                            <Button variant="contained" color="primary" onClick={handleRepurchase}>{`Volver a ${name}`}</Button> :
                                                            <Button variant="outlined" color="secondary" onClick={handleUpgrade}>Mejorar membresía</Button>
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Button color="primary" className="px-0 py-2" variant='text' endIcon={<i className={clsx('ri-arrow-right-s-line', classes.arrowReadMoreButton)}></i>} size='small' onClick={handleReadMore}>
                                                        <Typography variant='caption' color='secondary'>
                                                            Leer más acerca de las membresías
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    {
                                        id === "freemium" && plan.expiredUserMembership &&
                                        <Box className="mt-4 pl-2">
                                            <Grid container spacing={0} alignItems='center' justifyContent='space-between'>
                                                <Grid item></Grid>
                                                <Grid item>
                                                    <Button variant="contained" color="primary" onClick={handleRepurchase}>
                                                        <Grid container>
                                                            <Grid item>
                                                                Volver a
                                                            </Grid>
                                                            <Grid item className='ml-1'>
                                                                {expiredIcon}
                                                            </Grid>
                                                            <Grid item className='ml-1'>
                                                                {plan.expiredUserMembership.name}
                                                            </Grid>
                                                        </Grid>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </LoaderContent>
    );
};

export default MembershipCardContent;