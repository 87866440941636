import React from 'react';
import PropTypes from 'prop-types';
import Base from '../components/Base';

// Styles
import useStyles from "../../../assets/styles/components/cards/statusCourseCard/statusCourseCardStyle";

// Components
import StatusCourseMedia from './components/StatusCourseMedia';
import StatusCourseContent from './components/StatusCourseContent';

const StatusCourseCard = ({
    courseId, 
    userId, 
    sourceIdLogo, 
    enrollmentStatusId, 
    media, 
    content, 
    isLogged, 
    onClickGoToCourse, 
    onClickRegister, 
    onClickGoToDetail, 
    onSaveCourse, 
    noAvailable,
    tagPeriodo,
    approved,
    userMembership,
    isSecondEnrrollment
}) => {

    const classes = useStyles();

    return (
        <Base className={classes.rootBase}> 
            <StatusCourseMedia media={media} enrollmentStatusId={enrollmentStatusId} sourceIdLogo={sourceIdLogo} approved={approved}/>
            <StatusCourseContent 
                approved={approved}
                completed={media.percentage === 100} 
                content={content} 
                onSaveCourse={onSaveCourse} 
                noAvailable={noAvailable} 
                tagPeriodo={tagPeriodo} 
                courseId={courseId} 
                isLogged={isLogged} 
                userId={userId} 
                enrollmentStatusId={enrollmentStatusId} 
                onClickGoToCourse={onClickGoToCourse} 
                onClickRegister={onClickRegister} 
                onClickGoToDetail={onClickGoToDetail}
                userMembership={userMembership}
                isSecondEnrrollment={isSecondEnrrollment}
            />
        </Base>
    );
};

StatusCourseCard.defaultProps  = {
    isListItemCard: false,
    isLogged: false,
};

StatusCourseCard.propTypes = {
    courseId: PropTypes.number.isRequired,
    userId: PropTypes.number,
    sourceIdLogo: PropTypes.number,
    enrollmentStatusId: PropTypes.number,
    media: PropTypes.shape({
        backgroundImage: PropTypes.string,
        modalityId: PropTypes.number,
        percentage: PropTypes.number,
    }).isRequired,
    content: PropTypes.shape({
        courseName: PropTypes.string.isRequired,
    }).isRequired,
    isLogged: PropTypes.bool,
    onClickGoToCourse: PropTypes.func,
    onClickRegister: PropTypes.func,
    onClickGoToDetail: PropTypes.func,
    onSaveCourse: PropTypes.func,
};

export default StatusCourseCard;