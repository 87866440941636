import React from 'react';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { generatePath } from "react-router";

// redux
import { useHistory } from 'react-router-dom';

//Routes
import * as paths from '../../../routes/paths'
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';

// @material-ui/core
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CarouselAliados from '../CarouselAliados/CarouselAliados'

// shared core components
import { log } from '../../../shared/utils/console.js'
import {SOURCES} from '../../../design-system/constants/sources'
import AllyCourseCard from '../../../design-system/components/Cards/AllyCourseCard/AllyCourseCard';
import { v4 as uuid } from 'uuid';
import { CourseService } from '@sdk-point/talisis';

import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import clsx from 'clsx';
const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);



const useStyles = makeStyles((theme) => ({
    container:{
        padding: '28px 0 56px',
        '& .container':{
            display: 'flex',
            gap: 40,
            flexDirection: 'column'
        },
        '& .MuiCard-root.MuiPaper-outlined':{
            width: '100% !important',
            '& .MuiButtonBase-root .MuiCardContent-root .MuiCardContent-root':{
                height: '100% !important',
                justifyContent: 'space-between !important',
                '& .MuiBox-root:first-child':{
                    alignItems: 'flex-start'
                },
            },
        }
    }
}));

const Allys = () => {
    const classes = useStyles();
    const [aliados, setAliados] = React.useState([
        {
            "id": 12,
            "value": "Google SkillShop",
            "total_cursos": 0,
            "slug": "google_skillshop"
        },
        {
            "id": 6,
            "value": "LinkedIn Learning",
            "total_cursos": 0,
            "slug": "linkedin_learning"
        },
        {
            "id": 14,
            "value": "Hubspot Academy",
            "total_cursos": 0,
            "slug": "hubspot_academy"
        },
        {
            "id": 15,
            "value": "IE Publishing",
            "total_cursos": 0,
            "slug": "ie_publishing"
        },
        {
            "id": 13,
            "value": "Crehana",
            "total_cursos": 0,
            "slug": "crehana"
        },
        // {
        //     "id": 19,
        //     "value": "AWS Skill Builder",
        //     "total_cursos": 0,
        //     "slug": "aws_skill_builder"
        // },
        {
            "id": SOURCES.UNID,
            "value": "UNID",
            "total_cursos": 0,
            "slug": "unid",
        },
        {
            "id": SOURCES.UERRE,
            "value": "U-ERRE",
            "total_cursos": 599,
            "slug": "u-erre",
        },
        {
            "id": SOURCES.COURSERA,
            "value": "Coursera",
            "total_cursos": 0,
            "slug": "coursera"
        },
    ]);
    const history = useHistory();

    const getData = async () => {
        try {
            const data = await courseService.getCoursesAliados();
            return data?.response;
        } catch (e) {
            log(e.message);
        }
    };

    React.useEffect(() => {
        let isCanceled = true;
        getData().then((data)=>{
            if(isCanceled && data){
                const _aliados = aliados.map(it => ({...it, total_cursos: data.find(t => t.id === it.id).total_cursos}));
                setAliados(_aliados);
            }
        }).catch((err) => {
          log(`Failed: ${err}`);
        });
        return () => (isCanceled = false);
    }, []);


    const goTo = () => {
        const eventData = {};
        eventData['source'] = 'landing';
        eventTracker('ver_membresías', eventData, ['mixpanel', 'google','hubspot' ]);
        //
        history.push(paths.MEMBRESIAS);
    };

    const handleClick = ({value:allyName, slug, id}) => {
        let path = '';

        if(id === SOURCES.UNID || id === SOURCES.UERRE){
            path = generatePath(paths.ESCUELAS_OUS, {brand: slug})
        }else{
            const formattedName = allyName.toLocaleLowerCase().replace(" ", "-");
            path = generatePath(paths.ALIADOS, {name: formattedName});
        }
        eventData['source'] = 'landing';
        eventData['marcaCurso'] = slug ? (slug.split("_")[0]).replace('-','') : '';
        eventTracker('explorar_marca', eventData, ['mixpanel']);

        history.push(path);
    };

    return (
        <>
            <Hidden smDown>
                <ResponsiveContainer className={classes.container}>
                    <Typography variant='h2'>Accede a los cursos de nuestros aliados</Typography>
                    <Grid id="carrusel-aliados" container spacing={3}>
                        {
                            aliados.map(aliado => {
                                return (
                                    <Grid item md={4} lg={3} xs={12} key={uuid()}>
                                        <AllyCourseCard 
                                            sourceId={aliado.id} 
                                            sourceName={aliado.value} 
                                            courses={aliado.total_cursos}
                                            onClick={() => handleClick(aliado)}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Box>
                        <Button color='primary' endIcon={<DsIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" icon='t-arrow-right-line' />} onClick={(event) => goTo(event, paths.MEMBRESIAS)}>Comparar membresías</Button>
                    </Box>

                </ResponsiveContainer>
            </Hidden>
            <Hidden mdUp>
                <Box>
                    <CarouselAliados
                        items={aliados}
                        className={classes.allysCarousel}
                        landing={true}
                        title='Accede a los cursos de nuestros aliados'
                        onClickCard={handleClick}
                    />
                </Box>
            </Hidden>
        </>
    );
};

export default Allys;